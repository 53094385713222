import { Input } from "@/components/ui/input";
import React, { useEffect, useRef, useState } from "react";

// Ref: https://stackoverflow.com/questions/65011555/auto-scaling-input-to-width-of-value-in-react
// Keep an eye on browser support for field-sizing prop: https://developer.mozilla.org/en-US/docs/Web/CSS/field-sizing
const AutosizeInput = ({
	className,
	value,
	type,
	style,
	onChange,
	placeholder,
	...props
}: React.InputHTMLAttributes<HTMLInputElement>) => {
	const [content, setContent] = useState(value || placeholder);
	const span = useRef<HTMLSpanElement>(null);
	const input = useRef<HTMLInputElement>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: intentional
	useEffect(() => {
		if (input.current && span.current) {
			input.current.style.width = `${span.current.offsetWidth + 30}px`;
		}
	}, [content]);

	return (
		<>
			<span className="absolute opacity-0 -z-50 white-space-pre" ref={span}>
				{content}
			</span>
			<Input
				{...props}
				className={className}
				defaultValue={value}
				placeholder={placeholder}
				type="text"
				onChange={(event) => {
					if (event.target.value) {
						setContent(event.target.value);
					} else {
						setContent(placeholder);
					}
					if (onChange) {
						onChange(event);
					}
				}}
				ref={input}
				style={style}
			/>
		</>
	);
};

export default AutosizeInput;
