const pad = (n: number) => (n < 10 ? `0${n}` : n);

export const formatTimestamp = (seconds: number) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);
	return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};

export function parseSeconds(value: string): number | undefined {
	const parts = value.match(/.{1,2}/g)?.map(Number) ?? [];
	if (parts.length !== 3) {
		return;
	}
	return parts[0] * 3600 + parts[1] * 60 + parts[2];
}
