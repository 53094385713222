import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/spinner";
import { useForgotPassword } from "@/hooks/use-auth";

export const Route = createFileRoute("/_auth/forgot")({
	component: ForgotPassword,
});

function ForgotPassword() {
	const [email, setEmail] = useState("");
	const { mutate, isPending } = useForgotPassword();

	return (
		<div className="flex-col w-full max-w-sm px-4">
			<div className="text-2xl font-bold text-center">Forgot Password</div>
			<div className="text-sm text-center mt-2 text-muted-foreground">
				Please provide your email address
			</div>
			<form
				className="mt-4"
				onSubmit={(e) => {
					e.preventDefault();
					mutate({ email });
				}}
			>
				<Input
					className="mt-2"
					type="email"
					placeholder="e.g john@doe.com"
					autoCapitalize="none"
					autoComplete="email"
					autoCorrect="off"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					disabled={isPending}
				/>
				<Button
					id="send-password-reset-code"
					type="submit"
					className="w-full mt-4"
					disabled={isPending || !email}
				>
					{isPending && <Spinner className="h-4 w-4 mr-2" />}
					Send password reset code
				</Button>
			</form>
		</div>
	);
}
