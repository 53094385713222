/**
 * A light wrapper around the Worker API that provides strong typing for the messages passed
 */
export function createWorker({
	onMessage,
}: { onMessage: (message: Message) => void }) {
	const worker = new Worker(new URL("./main.ts", import.meta.url), {
		type: "module",
	});

	worker.onmessage = ({ data }: MessageEvent<Message>) => {
		onMessage(data);
	};

	return {
		postMessage: (message: Message) => {
			worker.postMessage(message);
		},
	};
}

export enum MessageKind {
	UserContext = "USER_CONTEXT",
	ResourcesChanged = "RESOURCES_CHANGED",
}

export type Message = UserContext | ResourcesChanged;

export type UserContext = {
	kind: MessageKind.UserContext;
	data: {
		token: string;
		userId: string;
	} | null;
};

export type ResourcesChanged = {
	kind: MessageKind.ResourcesChanged;
};
