import { cn } from "@/components/lib/utils";
import { Loader2 } from "lucide-react";
import { forwardRef } from "react";

export interface SpinnerProps extends React.HTMLAttributes<SVGSVGElement> {}

const Spinner = forwardRef<SVGSVGElement, SpinnerProps>(
	({ className, ...props }, ref) => {
		return (
			<Loader2 className={cn("animate-spin", className)} ref={ref} {...props} />
		);
	},
);
Spinner.displayName = "Spinner";

export { Spinner };
