import { Output } from "@/async/ffmpeg";
import { Button } from "@/components/ui/button";
import { ErrorAlert } from "@/components/ui/error-alert";
import { InfoButton } from "@/components/ui/info-button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/spinner";
import { useToast } from "@/components/ui/use-toast";
import { useConvertMedia, useLoadFFmpeg } from "@/hooks/use-ffmpeg";
import { useNewMedia } from "@/hooks/use-media";
import { Media } from "@/routes/techniques/components/media";
import { Check, Circle } from "lucide-react";
import { useState } from "react";

type Source = {
	file: File;
	type: "video" | "image";
};

export function ExternalMediaForm({
	techniqueId,
	onSuccess,
}: { techniqueId: string; onSuccess: () => void }) {
	const { isPending, isError, error } = useLoadFFmpeg();
	const [source, setSource] = useState<Source | null>(null);
	const [media, setMedia] = useState<Output | null>(null);

	if (isPending) {
		return (
			<div className="flex justify-center items-center">
				<Spinner className="w-8 h-8" />
			</div>
		);
	}

	if (isError) {
		return <ErrorAlert title="Error loading assets" description={`${error}`} />;
	}

	return (
		<div className="flex flex-col space-y-5">
			<div className="flex space-x-3 items-center">
				{source ? (
					<Check className="w-4 h-4" />
				) : (
					<Circle className="w-4 h-4" />
				)}
				<Label className="text-md">Select video or image</Label>
				<InfoButton id="select-video-or-image-info">
					Select a video or image file that is already formatted to add to this
					technique.
				</InfoButton>
			</div>
			{!source ? (
				<ChooseFile setSource={setSource} />
			) : (
				<>
					<div className="flex space-x-3 items-center">
						{media ? (
							<Check className="w-4 h-4" />
						) : (
							<Circle className="w-4 h-4" />
						)}
						<Label className="text-md">Optimize media</Label>
						<InfoButton id="optimize-media-info">
							Optimize the media file to reduce its size and improve performance
							for the web. The optimized file must be smaller than 10MB.
						</InfoButton>
					</div>
					{!media ? (
						<OptimizeMedia source={source} setMedia={setMedia} />
					) : (
						<>
							<div className="flex space-x-3 items-center">
								<Circle className="w-4 h-4" />
								<Label className="text-md">Confirm Details</Label>
							</div>
							<ConfirmStep
								techniqueId={techniqueId}
								media={media}
								onSuccess={onSuccess}
							/>
						</>
					)}
				</>
			)}
		</div>
	);
}

function ChooseFile({ setSource }: { setSource: (source: Source) => void }) {
	const { toast } = useToast();

	return (
		<Input
			id="source"
			type="file"
			accept="video/*,image/*"
			onChange={(event) => {
				if (!event.target.files || event.target.files.length === 0) {
					toast({
						variant: "destructive",
						title: "No file selected",
						description: "Please select an image or video file",
					});
					return;
				}
				const file = event.target.files[0];
				setSource({
					file,
					type: file.type.startsWith("video") ? "video" : "image",
				});
			}}
		/>
	);
}

function OptimizeMedia({
	source,
	setMedia,
}: {
	source: Source;
	setMedia: (media: Output) => void;
}) {
	const { toast } = useToast();
	const [progress, setProgress] = useState<number>(0);
	const { mutate: convertMedia, isPending } = useConvertMedia({
		onProgress: (progress) => {
			// progress seems to flash a high number when it starts
			if (progress > 100) {
				setProgress(0);
				return;
			}
			setProgress(progress);
		},
		onSuccess: (output) => {
			if (output.blob.size > 10 * 1024 * 1024) {
				toast({
					variant: "destructive",
					title: "File too large",
					description: `Please select a smaller file. This file is still ${
						output.blob.size / 1024 / 1024
					}MB after processing and must be smaller than 10MB.`,
				});
				return;
			}
			setMedia(output);
		},
	});

	return (
		<div className="flex flex-col">
			<div className="mb-3">
				<Media
					data={source.file}
					type={source.type}
					kind="file"
					alt="confirm"
					controls
				/>
			</div>
			<Button
				id="optimize-external-media"
				className="my-3"
				disabled={isPending}
				type="button"
				onClick={() =>
					convertMedia({
						input: source.file,
					})
				}
			>
				{isPending && <Spinner className="mr-2 h-4 w-4" />}
				{!isPending ? "Optimize" : `${Math.round(progress)}%`}
			</Button>
		</div>
	);
}

function ConfirmStep({
	techniqueId,
	media,
	onSuccess,
}: {
	techniqueId: string;
	media: Output;
	onSuccess: () => void;
}) {
	const { mutate: addMedia } = useNewMedia(techniqueId);
	return (
		<div className="flex flex-col">
			<Media
				type={media.type}
				data={media.blob}
				kind="blob"
				alt="preview"
				autoPlay
				playsInline
				muted
				loop
				controls
			/>

			<Button
				id="confirm-external-media"
				className="mt-6"
				onClick={() => {
					addMedia(
						{
							kind: "blob",
							source: media.blob,
							type: media.type,
							origin: media.origin,
							aspectRatio: media.aspectRatio,
							ext: media.ext,
						},
						{
							onSuccess,
						},
					);
				}}
			>
				Confirm
			</Button>
		</div>
	);
}
