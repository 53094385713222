import AutosizeInput from "@/components/ui/autosize-input";
import { Button } from "@/components/ui/button";
import { CirclePlus } from "lucide-react";
import {
	EdgeProps,
	BaseEdge,
	EdgeLabelRenderer,
	getSmoothStepPath,
	useReactFlow,
} from "reactflow";

export function TechniquesEdge({
	mode,
	props: {
		id,
		sourceX,
		sourceY,
		targetX,
		targetY,
		sourcePosition,
		targetPosition,
		markerEnd,
		data,
		selected,
	},
}: { mode: "view" | "edit"; props: EdgeProps<EdgeData> }) {
	const { setEdges } = useReactFlow();
	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
		sourcePosition,
		targetPosition,
	});

	const setEdgeLabel = (label: string | null) => {
		setEdges((edges) =>
			edges.map((edge) =>
				edge.id === id
					? {
							...edge,
							data: {
								...edge.data,
								label,
							},
					  }
					: edge,
			),
		);
	};

	return (
		<>
			<BaseEdge
				id={id}
				path={edgePath}
				markerEnd={markerEnd}
				style={{
					strokeWidth: 1,
					stroke:
						selected && mode === "edit"
							? "hsl(var(--selected))"
							: "hsl(var(--primary))",
				}}
			/>
			{mode === "edit" && selected ? (
				<EdgeLabelRenderer>
					{data?.label !== null ? (
						<AutosizeInput
							className="nodrag nopan"
							placeholder="Label"
							value={data?.label}
							onChange={(event) =>
								event.target.value
									? setEdgeLabel(event.target.value)
									: setEdgeLabel(null)
							}
							style={{
								position: "absolute",
								transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
								pointerEvents: "all",
							}}
						/>
					) : (
						<Button
							id="add-label"
							variant="outline"
							className="nodrag nopan rounded-full"
							style={{
								position: "absolute",
								transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
								pointerEvents: "all",
							}}
							onClick={() => setEdgeLabel("")}
						>
							<CirclePlus className="w-5 h-5" />
						</Button>
					)}
				</EdgeLabelRenderer>
			) : (
				data?.label && (
					<EdgeLabelRenderer>
						<div
							className="rounded-lg border bg-card text-card-foreground shadow-sm py-1 px-2"
							style={{
								position: "absolute",
								transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
								pointerEvents: "all",
							}}
						>
							{data.label}
						</div>
					</EdgeLabelRenderer>
				)
			)}
		</>
	);
}
