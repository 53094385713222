import { getYoutubeClip, getYoutubeInfo } from "@/async/api";
import { useToast } from "@/components/ui/use-toast";
import { YoutubeMetadata } from "@/hooks/types";
import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";

export function useYoutubeMetadata({
	onSuccess,
}: {
	onSuccess: (metadata: YoutubeMetadata) => void;
}) {
	const { getToken } = useAuth();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async ({ url }: { url: string }) => {
			const token = await getToken();
			const info = await getYoutubeInfo(url, token);
			return {
				...info,
				url,
			};
		},
		onSuccess,
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: error.message,
			});
		},
	});
}

export function useYoutubeDownload({
	onSuccess,
}: {
	onSuccess: (data: Blob) => void;
}) {
	const { getToken } = useAuth();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async ({
			source,
			start,
			duration,
		}: { source: string; start: number; duration: number }) => {
			const token = await getToken();
			return getYoutubeClip(source, start, duration, token);
		},
		onSuccess,
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: error.message,
			});
		},
	});
}
