import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/spinner";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { useResetPassword } from "@/hooks/use-auth";

export const Route = createFileRoute("/_auth/reset")({
	component: ResetPassword,
});

function ResetPassword() {
	const [password, setPassword] = useState("");
	const [code, setCode] = useState("");
	const { mutate, isPending } = useResetPassword();

	return (
		<div className="w-full max-w-sm">
			<div className="text-2xl text-bold text-center">Reset Password</div>
			<div className="flex items-center justify-center mt-4">
				<div className="text-sm text-center text-muted-foreground w-60">
					Enter a new password and the code sent to your email address
				</div>
			</div>
			<form
				className="mt-2 flex items-center justify-center"
				onSubmit={(e) => {
					e.preventDefault();
					mutate({ code, password });
				}}
			>
				<div className="flex-col">
					<div className="grid gap-2 mt-4 w-60">
						<Label className="text-muted-foreground" htmlFor="password">
							New Password
						</Label>
						<Input
							id="password"
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							disabled={isPending}
						/>
					</div>
					<div className="grid gap-2 mt-4">
						<Label className="text-muted-foreground" htmlFor="reset">
							Reset Code
						</Label>
						<InputOTP
							id="reset"
							maxLength={6}
							value={code}
							onChange={(value) => setCode(value)}
							disabled={isPending}
						>
							<InputOTPGroup className="bg-background">
								<InputOTPSlot index={0} />
								<InputOTPSlot index={1} />
								<InputOTPSlot index={2} />
								<InputOTPSlot index={3} />
								<InputOTPSlot index={4} />
								<InputOTPSlot index={5} />
							</InputOTPGroup>
						</InputOTP>
					</div>
					<Button
						id="reset-password"
						type="submit"
						className="w-60 mt-6"
						disabled={isPending || !code || !password}
					>
						{isPending && <Spinner className="h-4 w-4 mr-2" />}
						Reset
					</Button>
				</div>
			</form>
		</div>
	);
}
