import { usePostUserContext, useWorker } from "@/hooks/use-worker";
import { useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";

/**
 * Initialize the worker
 * Pass the token to the worker every time it changes
 * It doesn't seem like there is a good way to listen for new tokens with clerk
 * But it seems like the tokens expire every minute and are refetched 15 seconds before they expire
 * so we just update the token when it is relevant
 * - every 10 seconds
 * - on initial page load
 * - when the user signs in/out
 */
export function WorkerProvider({ children }: { children: React.ReactNode }) {
	const { data: worker } = useWorker();
	const { mutate: postUserContext } = usePostUserContext();
	const { isSignedIn } = useAuth();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (worker) {
			postUserContext();
			const interval = setInterval(() => {
				postUserContext();
			}, 10000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [isSignedIn, postUserContext, worker]);

	return <>{children}</>;
}
