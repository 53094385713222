import { forwardRef } from "react";

export interface YoutubeEmbedProps
	extends React.IframeHTMLAttributes<HTMLIFrameElement> {
	embedId: string;
}

const YoutubeEmbedded = forwardRef<HTMLIFrameElement, YoutubeEmbedProps>(
	({ embedId, ...props }, ref) => {
		return (
			<iframe
				{...props}
				ref={ref}
				src={`https://www.youtube.com/embed/${embedId}`}
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
				title="Embedded youtube"
			/>
		);
	},
);
YoutubeEmbedded.displayName = "YoutubeEmbedded";

export { YoutubeEmbedded };
