import { syncResources } from "@/async/api";
import { useAuth } from "@clerk/clerk-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function useSyncResources() {
	const { userId, getToken, isSignedIn } = useAuth();
	const queryClient = useQueryClient();
	return useQuery({
		queryKey: ["sync", userId],
		queryFn: async () => {
			try {
				if (!isSignedIn) {
					return true;
				}
				const token = await getToken();
				if (!token || !userId) {
					return true;
				}
				await syncResources(userId, token);
				queryClient.invalidateQueries({ queryKey: ["systems"] });
				queryClient.invalidateQueries({ queryKey: ["techniques"] });
				queryClient.invalidateQueries({ queryKey: ["media"] });
				return true;
			} catch (ex) {
				console.error("Failed to sync: ", ex);
				return true; // we don't want to block the UI even if sync fails
			}
		},
		refetchOnMount: false,
	});
}
