import { useGetSystems, useNewSystem } from "@/hooks/use-systems";
import { Systems } from "@/routes/systems";
import { EditSystem } from "@/routes/systems/$id_.edit";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { z } from "zod";

const homeSchema = z.object({
	techniqueId: z.string().optional(),
	systemId: z.string().optional(),
});

export const Route = createFileRoute("/")({
	component: Home,
	validateSearch: homeSchema,
});

// A component mostly to direct users for improved onboarding
// I.e if you don't have a system yet, make one and then nagivagate to it
function Home() {
	const { systemId, techniqueId } = Route.useSearch();
	const { data: systems, isPending: isPendingGet, isError } = useGetSystems();
	const { mutate: newSystem, isPending: isPendingMutate } = useNewSystem();
	const navigate = useNavigate();

	useEffect(() => {
		if (isPendingGet || isError) {
			return;
		}
		if (systems.length === 0) {
			newSystem(undefined, {
				onSuccess: (id) => navigate({ search: { systemId: id } }),
			});
		}
	}, [systems, isPendingGet, isError, newSystem, navigate]);

	if (isPendingGet || isPendingMutate) {
		return null;
	}

	if (systemId) {
		return <EditSystem id={systemId} techniqueId={techniqueId} />;
	}

	return <Systems />;
}
