import { Button } from "@/components/ui/button";
import { Globe, Info, MoveLeft, MoveRight, Trash2 } from "lucide-react";
import { useGetTechnique, usePutTechnique } from "@/hooks/use-techniques";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { useDeleteMedia, useGetMedia, useMoveMedia } from "@/hooks/use-media";
import { Technique as TechniqueType } from "@/hooks/types";
import { useState } from "react";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
	ResizablePanelGroup,
	ResizablePanel,
	ResizableHandle,
} from "@/components/ui/resizable";
import { AutosizeTextarea } from "@/components/ui/autosize-textarea";
import { Markdown } from "@/components/ui/markdown";
import { NewMediaButton } from "@/routes/techniques/components/new-media-button";
import { Media } from "@/routes/techniques/components/media";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@/components/ui/popover";
import { Badge } from "@/components/ui/badge";

export function Technique({
	id,
	isEditing,
}: { id: string; isEditing?: boolean }) {
	const { data: technique } = useGetTechnique(id);

	if (!technique) {
		return null;
	}

	return (
		<>
			<div className="flex flex-row-reverse">
				<GlobalButton technique={technique} isEditing={isEditing} />
			</div>
			<Name technique={technique} isEditing={isEditing} />
			<div className="mt-14">
				<MediaCarousel technique={technique} isEditing={isEditing} />
			</div>
			<div className="mt-10">
				<Notes technique={technique} isEditing={isEditing} />
			</div>
		</>
	);
}

function Name({
	technique: { id, data },
	isEditing,
}: {
	technique: TechniqueType;
	isEditing?: boolean;
}) {
	const { mutate: putTechnique } = usePutTechnique(id);
	const [name, setName] = useState(data.name);

	return (
		<>
			{isEditing ? (
				<>
					<h1 className="mb-2">Name</h1>
					<Input
						id="techniqueName"
						value={name}
						onChange={(event) => {
							setName(event.target.value);
							putTechnique({
								...data,
								name: event.target.value,
							});
						}}
					/>
				</>
			) : (
				<h1 className="text-2xl font-semibold text-center mt-5 ml-6">
					{data.name}
				</h1>
			)}
		</>
	);
}

function MediaCarousel({
	technique: {
		id,
		data: { media },
	},
	isEditing,
}: { technique: TechniqueType; isEditing?: boolean }) {
	if (!media.length) {
		if (!isEditing) {
			return null;
		}
		return (
			<>
				<h1 className="mb-3">Clips</h1>
				<NewMediaButton techniqueId={id} className="w-full" />
			</>
		);
	}

	const basis = () => {
		if (media.length === 1) {
			return "md:basis-full";
		}
		if (media.length === 2) {
			return "md:basis-1/2";
		}
		return "md:basis-1/2 lg:basis-1/3";
	};

	return (
		<>
			{isEditing && (
				<div className="flex mb-3">
					<h1 className="grow content-end">Clips</h1>
					<NewMediaButton techniqueId={id} />
				</div>
			)}
			<Carousel className="w-full">
				<CarouselContent>
					{media.map((medium, index) => (
						<CarouselItem className={basis()} key={medium.id}>
							<MediaCard
								index={index}
								id={medium.id}
								techniqueId={id}
								isEditing={isEditing}
								length={media.length}
							/>
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
		</>
	);
}

function MediaCard({
	index,
	techniqueId,
	id,
	isEditing,
	length,
}: {
	index: number;
	techniqueId: string;
	id: string;
	isEditing?: boolean;
	length: number;
}) {
	const { mutate: moveMedia } = useMoveMedia(techniqueId);
	const { data: media } = useGetMedia(id);

	if (!media) {
		return null;
	}

	return (
		<div className="rounded-lg border bg-card text-card-foreground shadow-sm">
			<Media
				type={media.data.type}
				data={media.data.source}
				kind={media.data.kind}
				alt="media"
				className="w-full h-auto max-h-[467px] rounded-t-lg"
				autoPlay
				playsInline
				muted
				loop
				controls
			/>
			{!isEditing ? (
				<div className="text-center text-sm m-2">{media.data.origin}</div>
			) : (
				<div className="flex justify-between items-center">
					<Popover>
						<PopoverTrigger asChild>
							<Button variant="ghost">
								<Info className="h-4 w-4" />
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<div className="text-center">{media.data.origin}</div>
						</PopoverContent>
					</Popover>
					{index > 0 && (
						<Button
							id="move-media-left"
							variant="ghost"
							onClick={() => moveMedia({ mediaId: id, newIndex: index - 1 })}
						>
							<MoveLeft className="h-4 w-4" />
						</Button>
					)}
					{index < length - 1 && (
						<Button
							id="move-media-right"
							variant="ghost"
							onClick={() => moveMedia({ mediaId: id, newIndex: index + 1 })}
						>
							<MoveRight className="h-4 w-4" />
						</Button>
					)}
					<DeleteMedia techniqueId={techniqueId} mediaId={id} />
				</div>
			)}
		</div>
	);
}

function Notes({
	technique: { id, data },
	isEditing,
}: { technique: TechniqueType; isEditing?: boolean }) {
	const { mutate: putTechnique } = usePutTechnique(id);
	const [notes, setNotes] = useState(data.notes);

	return (
		<>
			{isEditing ? (
				<ResizablePanelGroup
					direction="horizontal"
					className="border-b border-input"
				>
					<ResizablePanel defaultSize={50}>
						<h1 className="mb-2">Notes</h1>
						<AutosizeTextarea
							className="border-t border-x border-b-0 focus-visible:ring-0"
							minHeight={150}
							placeholder="type your notes here"
							value={notes}
							onChange={(event) => {
								setNotes(event.target.value);
								putTechnique({ ...data, notes: event.target.value });
							}}
						/>
					</ResizablePanel>
					<ResizableHandle className="border-0 w-0 hover:border-1" />
					<ResizablePanel defaultSize={50}>
						<h1 className="mb-2">Preview</h1>
						<div className="p-2 bg-background h-full border-t border-x border-input ">
							<Markdown>{data.notes || "preview your notes here"}</Markdown>
						</div>
					</ResizablePanel>
				</ResizablePanelGroup>
			) : (
				<>
					{data.notes && (
						<div className="rounded-lg border border-input bg-background p-4">
							<Markdown>{data.notes}</Markdown>
						</div>
					)}
				</>
			)}
		</>
	);
}

function DeleteMedia({
	techniqueId,
	mediaId,
}: {
	techniqueId: string;
	mediaId: string;
}) {
	const { mutate: deleteMedia } = useDeleteMedia(techniqueId);
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<Dialog open={openDialog} onOpenChange={setOpenDialog}>
			<DialogTrigger asChild>
				<Button id="delete-media" variant="ghost">
					<Trash2 className="w-4 h-4" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Delete clip</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					Are you sure you want to delete this clip?
				</DialogDescription>
				<DialogFooter>
					<Button
						id="confirm-delete-media"
						variant="destructive"
						onClick={() =>
							deleteMedia(mediaId, {
								onSuccess: () => setOpenDialog(false),
							})
						}
					>
						Delete Clip
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

function GlobalButton({
	technique,
	isEditing,
}: {
	technique: TechniqueType;
	isEditing?: boolean;
}) {
	const { mutate: putTechnique } = usePutTechnique(technique.id);
	const [openDialog, setOpenDialog] = useState(false);

	if (!isEditing) {
		if (!technique.data.associatedSystem) {
			return (
				<Badge>
					<Globe className="mr-1 inline-block h-4 w-4" />
					Global
				</Badge>
			);
		}
		return null;
	}

	if (!technique.data.associatedSystem) {
		return (
			<Badge>
				<Globe className="mr-1 inline-block h-4 w-4" />
				Global
			</Badge>
		);
	}

	return (
		<Dialog open={openDialog} onOpenChange={setOpenDialog}>
			<DialogTrigger asChild>
				<Button variant="outline">
					<Globe className="w-4 h-4 mr-2" />
					Make Global
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Make Technique Global</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					This will make the technique accessable from all Systems and it will
					show up in the global list and Techniques page.
				</DialogDescription>
				<DialogFooter>
					<Button
						id="confirm-delete-media"
						variant="default"
						onClick={() =>
							putTechnique(
								{ ...technique.data, associatedSystem: null },
								{
									onSuccess: () => setOpenDialog(false),
								},
							)
						}
					>
						Confirm
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
