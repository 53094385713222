import { cn } from "@/components/lib/utils";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTrigger,
} from "@/components/ui/dialog";
import { VideoForm } from "@/routes/techniques/components/video-form";
import { YoutubeForm } from "@/routes/techniques/components/youtube-form";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Clapperboard } from "lucide-react";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ExternalMediaForm } from "@/routes/techniques/components/external-form";
import Subscribed from "@/components/ui/subscribed";
import { useUser } from "@/hooks/use-user";

export function NewMediaButton({
	techniqueId,
	className,
}: { techniqueId: string; className?: string }) {
	const [openDialog, setOpenDialog] = useState(false);
	const { data: user } = useUser();
	return (
		<Dialog open={openDialog} onOpenChange={setOpenDialog}>
			<DialogTrigger asChild>
				<Button id="add-media" variant="outline" className={cn(className)}>
					<Clapperboard className="mr-2 w-4 h-4" /> Add Clip
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogDescription>
					<ScrollArea viewPortClassName=" max-h-[calc(100vh-100px)]">
						<Tabs
							defaultValue="instructional"
							className="w-full"
							onValueChange={(value) => {
								if (window.fathom) {
									window.fathom.trackEvent(value);
								}
							}}
						>
							<TabsList className="mb-5 w-full">
								<TabsTrigger value="instructional" className="w-full">
									Clip Instructional
								</TabsTrigger>
								<TabsTrigger value="external" className="w-full">
									Add External Clip
								</TabsTrigger>
								<Subscribed
									disabled={!user?.active}
									text="Subscribe to extract Youtube clips as media"
								>
									<TabsTrigger value="youtube" className="w-full">
										Clip Youtube
									</TabsTrigger>
								</Subscribed>
							</TabsList>
							<TabsContent value="instructional">
								<VideoForm
									techniqueId={techniqueId}
									onSuccess={() => setOpenDialog(false)}
								/>
							</TabsContent>
							<TabsContent value="youtube">
								<YoutubeForm
									techniqueId={techniqueId}
									onSuccess={() => setOpenDialog(false)}
								/>
							</TabsContent>
							<TabsContent value="external">
								<ExternalMediaForm
									techniqueId={techniqueId}
									onSuccess={() => setOpenDialog(false)}
								/>
							</TabsContent>
						</Tabs>
					</ScrollArea>
				</DialogDescription>
			</DialogContent>
		</Dialog>
	);
}
