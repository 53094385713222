import { cn } from "@/components/lib/utils";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";
import { forwardRef } from "react";

export interface InfoButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const InfoButton = forwardRef<HTMLButtonElement, InfoButtonProps>(
	({ className, children, ...props }, ref) => {
		return (
			<Popover>
				<PopoverTrigger asChild>
					<Button
						className={cn("p-0 h-5", className)}
						variant="ghost"
						ref={ref}
						{...props}
					>
						<Info className="h-5 w-5" />
					</Button>
				</PopoverTrigger>
				<PopoverContent>{children}</PopoverContent>
			</Popover>
		);
	},
);

InfoButton.displayName = "InfoButton";

export { InfoButton };
