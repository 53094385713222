import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp";

function TimestampInput({
	disabled,
	value,
	onChange,
}: {
	disabled?: boolean;
	value: string;
	onChange: (value: string) => void;
}) {
	return (
		<InputOTP
			maxLength={6}
			disabled={disabled}
			value={value}
			onChange={onChange}
		>
			<InputOTPGroup>
				<InputOTPSlot index={0} className="h-8 w-8" placeholder="h" />
				<InputOTPSlot index={1} className="h-8 w-8" placeholder="h" />
			</InputOTPGroup>
			<div className="text-lg font-bold text-foreground">:</div>
			<InputOTPGroup>
				<InputOTPSlot index={2} className="h-8 w-8" placeholder="m" />
				<InputOTPSlot index={3} className="h-8 w-8" placeholder="m" />
			</InputOTPGroup>
			<div className="text-lg font-bold text-foreground">:</div>
			<InputOTPGroup>
				<InputOTPSlot index={4} className="h-8 w-8" placeholder="s" />
				<InputOTPSlot index={5} className="h-8 w-8" placeholder="s" />
			</InputOTPGroup>
		</InputOTP>
	);
}

export { TimestampInput };
