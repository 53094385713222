import { cn } from "@/components/lib/utils";
import { Button } from "@/components/ui/button";
import { Technique } from "@/hooks/types";
import {
	useGetTechnique,
	useGetTechniqueThumbnail,
} from "@/hooks/use-techniques";
import { Media } from "@/routes/techniques/components/media";
import { Link } from "@tanstack/react-router";
import { CircleX } from "lucide-react";
import { NodeProps, Handle, Position, useReactFlow } from "reactflow";

export function TechniqueNode({
	mode,
	props: {
		id,
		selected,
		data: { techniqueId },
	},
}: { mode: "view" | "edit" | "thumbnail"; props: NodeProps<NodeData> }) {
	const { setNodes, setEdges } = useReactFlow();
	const { data: technique } = useGetTechnique(techniqueId);

	if (!technique) {
		return (
			<>
				<Handle id="left" type="source" position={Position.Left} />
				{mode === "edit" ? (
					<Button
						id="delete-missing-tech-node"
						variant="ghost"
						className="flex justify-center items-center"
						onClick={() => {
							setNodes((nodes) => nodes.filter((node) => node.id !== id));
							setEdges((edges) =>
								edges.filter(
									(edge) => edge.source !== id && edge.target !== id,
								),
							);
						}}
					>
						<CircleX className="w-8 h-8" />
					</Button>
				) : (
					<div className="rounded-lg border bg-card flex w-[150px] h-[85px] justify-center items-center">
						<CircleX className="w-8 h-8" />
					</div>
				)}
				<Handle id="right" type="source" position={Position.Right} />
			</>
		);
	}

	if (mode === "view") {
		return (
			<Link search={{ techniqueId }}>
				<TechniqueNodeContents
					technique={technique}
					mode={mode}
					selected={selected}
				/>
			</Link>
		);
	}

	return (
		<TechniqueNodeContents
			technique={technique}
			mode={mode}
			selected={selected}
		/>
	);
}

function TechniqueNodeContents({
	technique,
	selected,
	mode,
}: {
	technique: Technique;
	selected: boolean;
	mode: "view" | "edit" | "thumbnail";
}) {
	const { data: thumbnail } = useGetTechniqueThumbnail(technique.id);

	return (
		<>
			<Handle id="left" type="source" position={Position.Left} />
			<div
				className={cn(
					"rounded-lg border bg-card text-card-foreground shadow-sm p-4",
					selected && mode === "edit" ? "border border-selected" : "border",
					thumbnail && thumbnail.data.aspectRatio < 1
						? "max-w-[150px]"
						: "max-w-[225px]",
				)}
			>
				{thumbnail && (
					<Media
						className="rounded-lg mb-4"
						type={thumbnail.data.type}
						data={thumbnail.data.source}
						kind={thumbnail.data.kind}
						alt="thumbnail"
						autoPlay
						playsInline
						loop
						muted
					/>
				)}
				<div className="text-sm font-bold text-center">
					{technique.data.name}
				</div>
			</div>
			<Handle id="right" type="source" position={Position.Right} />
		</>
	);
}
