import { getCheckoutSession } from "@/async/api";
import { useToast } from "@/components/ui/use-toast";
import { useSignIn, useSignUp } from "@clerk/clerk-react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

export function useEmailSignIn() {
	const { isLoaded, signIn, setActive } = useSignIn();
	const navigate = useNavigate();
	const { toast } = useToast();

	return useMutation({
		mutationFn: async ({
			emailAddress,
			password,
		}: {
			emailAddress: string;
			password: string;
		}) => {
			if (!isLoaded) {
				return;
			}

			const { status, createdSessionId } = await signIn.create({
				identifier: emailAddress,
				password,
			});

			// Please see https://clerk.com/docs/references/react/use-sign-in#result-status for  more information
			if (status === "complete") {
				setActive({
					session: createdSessionId,
				});
			} else if (status === "needs_first_factor") {
				navigate({ to: "/verify" });
			} else if (
				status === "needs_new_password" ||
				status === "needs_identifier"
			) {
				await signIn.create({
					strategy: "reset_password_email_code",
					identifier: emailAddress,
				});
				navigate({ to: "/reset" });
			} else {
				throw new Error(`Unexpected sign in status: ${status}`);
			}
		},
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: parseClerkError(error),
			});
		},
	});
}

export function useEmailSignUp() {
	const navigate = useNavigate();
	const { isLoaded, signUp } = useSignUp();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async ({
			emailAddress,
			password,
		}: {
			emailAddress: string;
			password: string;
		}) => {
			if (!isLoaded) {
				return;
			}

			await signUp.create({
				emailAddress,
				password,
			});

			// Send the user an email with the verification code
			await signUp.prepareEmailAddressVerification({
				strategy: "email_code",
			});

			navigate({ to: "/verify" });
		},
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: parseClerkError(error),
			});
		},
	});
}

export function useVerifyEmail() {
	const { isLoaded, signUp, setActive } = useSignUp();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async ({ code }: { code: string }) => {
			if (!isLoaded) {
				return;
			}

			const { status, createdSessionId } =
				await signUp.attemptEmailAddressVerification({
					code,
				});

			// Please see https://clerk.com/docs/references/react/use-sign-up#result-status for  more information
			if (status === "complete") {
				await setActive({
					session: createdSessionId,
					beforeEmit: async (session) => {
						if (!session) {
							return;
						}
						const token = await session.getToken();
						const checkoutUrl = await getCheckoutSession(token);
						window.location.href = checkoutUrl;
					},
				});
				return;
			}

			throw new Error(`Unexpected sign up status: ${status}`);
		},
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: parseClerkError(error),
			});
		},
	});
}

export function useForgotPassword() {
	const { signIn } = useSignIn();
	const { toast } = useToast();
	const navigate = useNavigate();
	return useMutation({
		mutationFn: async ({ email }: { email: string }) => {
			await signIn?.create({
				strategy: "reset_password_email_code",
				identifier: email,
			});
			navigate({ to: "/reset" });
		},
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: parseClerkError(error),
			});
		},
	});
}

export function useResetPassword() {
	const { toast } = useToast();
	const { signIn, setActive, isLoaded } = useSignIn();
	return useMutation({
		mutationFn: async ({
			code,
			password,
		}: { code: string; password: string }) => {
			if (!isLoaded) {
				return;
			}

			const { status, createdSessionId } = await signIn.attemptFirstFactor({
				strategy: "reset_password_email_code",
				code,
				password,
			});

			if (status === "complete") {
				return setActive({ session: createdSessionId });
			}

			throw new Error(`Unexpected sign in status: ${status}`);
		},
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error",
				description: parseClerkError(error),
			});
		},
	});
}

function parseClerkError(
	error: Error & {
		errors?: {
			code: string;
			message: string;
			longMessage: string;
		}[];
	},
) {
	if (!error.errors) {
		return error.message;
	}

	return error.errors[0].longMessage;
}
