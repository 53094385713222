import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

export function ErrorAlert({
	title,
	description,
}: {
	title: string;
	description: string;
}) {
	return (
		<Alert variant="destructive" className="bg-background">
			<AlertCircle className="h-4 w-4" />
			<AlertTitle>{title}</AlertTitle>
			<AlertDescription>{description}</AlertDescription>
		</Alert>
	);
}
