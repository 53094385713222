import "reactflow/dist/style.css";
import "./system.css";

import { useDeleteSystem, useGetSystem } from "@/hooks/use-systems";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import ReactFlow, {
	Background,
	Connection,
	ConnectionMode,
	Edge,
	EdgeChange,
	EdgeProps,
	MarkerType,
	Node,
	NodeChange,
	NodeProps,
	Position,
	ReactFlowInstance,
	ReactFlowProvider,
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	updateEdge,
	useEdgesState,
	useNodesState,
} from "reactflow";
import { System, Technique } from "@/hooks/types";
import {
	useGetTechniqueThumbnail,
	useGetTechniques,
	useNewTechnique,
} from "@/hooks/use-techniques";
import { Media } from "@/routes/techniques/components/media";
import {
	ResizablePanelGroup,
	ResizablePanel,
	ResizableHandle,
} from "@/components/ui/resizable";
import { usePutSystem } from "@/hooks/use-systems";
import { nanoid } from "nanoid";
import { useState, useCallback, DragEvent } from "react";
import { TechniquesEdge } from "@/routes/systems/components/technique-edge";
import { Box, ChevronLeft, Eye, Pencil, Trash2 } from "lucide-react";
import AutosizeInput from "@/components/ui/autosize-input";
import { Button } from "@/components/ui/button";
import { DialogHeader, DialogFooter } from "@/components/ui/dialog";
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogTitle,
	DialogDescription,
} from "@/components/ui/dialog";
import { Link } from "@tanstack/react-router";
import { ScrollArea } from "@/components/ui/scroll-area";
import { z } from "zod";
import { ErrorAlert } from "@/components/ui/error-alert";
import { TechniqueSheet } from "@/routes/systems/components/technique-sheet";
import { TechniqueNode } from "@/routes/systems/components/technique-node";
import { Spinner } from "@/components/ui/spinner";
import { Center } from "@/components/ui/center";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useWindowSize } from "@/hooks/use-window-size";

const systemEditSchema = z.object({
	techniqueId: z.string().optional(),
});

export const Route = createFileRoute("/systems/$id/edit")({
	component: () => {
		const { id } = Route.useParams();
		const { techniqueId } = Route.useSearch();

		return <EditSystem id={id} techniqueId={techniqueId} />;
	},
	validateSearch: systemEditSchema,
});

export function EditSystem({
	id,
	techniqueId,
}: { id: string; techniqueId?: string }) {
	const { data: system, isPending, isError } = useGetSystem(id);
	const { mutate: putSystem } = usePutSystem(id);
	const { width } = useWindowSize();

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the system at this time."
				/>
			</Center>
		);
	}

	if (!system) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not find the system you are looking for."
				/>
			</Center>
		);
	}

	return (
		<div className="">
			<div className="flex h-14 w-full border-b items-center">
				<Button id="edit-system-back" variant="ghost" asChild>
					<Link to="/systems" params={{ id }}>
						<ChevronLeft className="w-4 h-4" />
					</Link>
				</Button>
				<AutosizeInput
					placeholder="System Name"
					className="bg-inherit border-none ml-1"
					value={system.data.name}
					onChange={(event) =>
						putSystem({ ...system.data, name: event.target.value })
					}
				/>
				<Pencil className="ml-2 w-4 h-4 mr-auto" />
				{/* <ShareSystemButton id={system.id} shared={system.shared} /> */}
				<Button id="edit-system-view" variant="ghost" className="" asChild>
					<Link to="/systems/$id" params={{ id }}>
						<Eye className="w-4 h-4" />
					</Link>
				</Button>
				<DeleteSystemButton systemId={system.id} />
			</div>
			<ResizablePanelGroup direction="horizontal">
				<ResizablePanel defaultSize={width <= 768 ? 50 : 70}>
					<SystemCanvas system={system} />
				</ResizablePanel>
				<ResizableHandle />
				<ResizablePanel
					defaultSize={width <= 768 ? 50 : 30}
					className="min-w-50"
				>
					<EditPanel systemId={id} />
				</ResizablePanel>
			</ResizablePanelGroup>
			<TechniqueSheet techniqueId={techniqueId} isEditing />
		</div>
	);
}

function SystemCanvas({ system }: { system: System }) {
	const { mutate: putSystem } = usePutSystem(system.id);
	const [reactFlowInstance, setReactFlowInstance] =
		useState<ReactFlowInstance | null>(null);
	const [nodes, setNodes, onNodesChange] = useNodesState(system.data.nodes);
	const [edges, setEdges, onEdgesChange] = useEdgesState(system.data.edges);
	const onNodesChangeSync = useCallback(
		(changes: NodeChange[]) => {
			putSystem({ ...system.data, nodes: applyNodeChanges(changes, nodes) });
			onNodesChange(changes);
		},
		[putSystem, system, nodes, onNodesChange],
	);
	const onEdgesChangeSync = useCallback(
		(changes: EdgeChange[]) => {
			putSystem({ ...system.data, edges: applyEdgeChanges(changes, edges) });
			onEdgesChange(changes);
		},
		[putSystem, system, edges, onEdgesChange],
	);
	const onEdgeUpdateSync = useCallback(
		(oldEdge: Edge, newConnection: Connection) => {
			const updatedEdges = updateEdge(oldEdge, newConnection, edges);
			putSystem({ ...system.data, edges: updatedEdges });
			setEdges(updatedEdges);
		},
		[system, putSystem, setEdges, edges],
	);
	const onConnectSync = useCallback(
		(params: Edge | Connection) => {
			const updatedEdges = addEdge(
				{
					...params,
					type: "techniquesEdge",
					markerEnd: {
						type: MarkerType.ArrowClosed,
						color: "hsl(var(--primary))",
						width: 20,
						height: 20,
					},
					data: {
						label: null,
					},
				},
				edges,
			);
			putSystem({ ...system.data, edges: updatedEdges });
			setEdges(updatedEdges);
		},
		[system, putSystem, setEdges, edges],
	);
	const onDragOver = useCallback((event: DragEvent) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);
	const onDropSync = useCallback(
		(event: DragEvent) => {
			event.preventDefault();

			const techniqueId = event.dataTransfer.getData("application/reactflow");

			if (!techniqueId || !reactFlowInstance) {
				return;
			}

			const newNode: Node<NodeData> = {
				id: nanoid(),
				position: reactFlowInstance.screenToFlowPosition({
					x: event.clientX,
					y: event.clientY,
				}),
				type: "technique",
				data: { techniqueId },
				sourcePosition: Position.Right,
				targetPosition: Position.Left,
			};
			const newNodes = nodes.concat(newNode);
			putSystem({ ...system.data, nodes: newNodes });
			setNodes(newNodes);
		},
		[reactFlowInstance, putSystem, system, setNodes, nodes],
	);

	return (
		<div className="h-[calc(100vh-112px)] sm:h-[calc(100vh-56px)] w-full">
			<ReactFlowProvider>
				<ReactFlow
					id="system-edit"
					onInit={setReactFlowInstance}
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChangeSync}
					onEdgesChange={onEdgesChangeSync}
					onEdgeUpdate={onEdgeUpdateSync}
					onConnect={onConnectSync}
					onDrop={onDropSync}
					onDragOver={onDragOver}
					fitView
					proOptions={{
						hideAttribution: true,
					}}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					connectionMode={ConnectionMode.Loose}
				>
					<Background />
				</ReactFlow>
			</ReactFlowProvider>
		</div>
	);
}

const nodeTypes = {
	technique: (props: NodeProps<NodeData>) => (
		<TechniqueNode mode="edit" props={props} />
	),
};

const edgeTypes = {
	techniquesEdge: (props: EdgeProps<EdgeData>) => (
		<TechniquesEdge mode="edit" props={props} />
	),
};

function EditPanel({ systemId }: { systemId: string }) {
	return (
		<ScrollArea className="h-[calc(100vh-112px)] sm:h-[calc(100vh-56px)]">
			<Tabs defaultValue="local" className="w-full mt-4">
				<TabsList className="w-full">
					<TabsTrigger value="local" className="w-full">
						Local
					</TabsTrigger>
					<TabsTrigger value="global" className="w-full">
						Global
					</TabsTrigger>
				</TabsList>
				<TabsContent value="local">
					<LocalTechniquesList systemId={systemId} />
				</TabsContent>
				<TabsContent value="global">
					<GlobalTechniquesList systemId={systemId} />
				</TabsContent>
			</Tabs>
		</ScrollArea>
	);
}

function LocalTechniquesList({ systemId }: { systemId: string }) {
	const navigate = useNavigate();
	const { mutate: newTechnique } = useNewTechnique();
	const { data: techniques } = useGetTechniques({ associatedSystem: systemId });

	if (!techniques) {
		return null;
	}

	return (
		<div>
			<Button
				id="new-technique-from-system"
				className="w-full my-4"
				variant="default"
				onClick={() =>
					newTechnique(
						{ associatedSystem: systemId },
						{
							onSuccess: (id) =>
								navigate({
									to: "/systems/$id/edit",
									params: { id: systemId },
									search: { techniqueId: id },
								}),
							onError: (error) => {
								console.error(error);
							},
						},
					)
				}
			>
				New Technique
				<Box className=" ml-2 w-4 h-4" />
			</Button>
			<div className="@container">
				<div className="grid gap-4 mt-4 grid-cols-1 @sm:grid-cols-2 @lg:grid-cols-3">
					{techniques.map((technique) => (
						<TechniqueCard
							key={technique.id}
							systemId={systemId}
							technique={technique}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

function GlobalTechniquesList({ systemId }: { systemId: string }) {
	const { data: techniques } = useGetTechniques({ associatedSystem: null });

	if (!techniques) {
		return null;
	}

	return (
		<div className="@container">
			<div className="grid gap-4 mt-4 grid-cols-1 @sm:grid-cols-2 @lg:grid-cols-3">
				{techniques.map((technique) => (
					<TechniqueCard
						key={technique.id}
						systemId={systemId}
						technique={technique}
					/>
				))}
			</div>
		</div>
	);
}

function TechniqueCard({
	systemId,
	technique,
}: {
	systemId: string;
	technique: Technique;
}) {
	const { data: thumbnail } = useGetTechniqueThumbnail(technique.id);
	const onDragStart = (event: DragEvent, techniqueId: string) => {
		event.dataTransfer.setData("application/reactflow", techniqueId);
		event.dataTransfer.effectAllowed = "move";
	};

	return (
		<div
			className="cursor-grab active:cursor-grabbing rounded-lg border bg-card text-card-foreground shadow-sm"
			key={technique.id}
			onDragStart={(event) => onDragStart(event, technique.id)}
			draggable
		>
			<div className="flex items-center justify-center">
				{thumbnail ? (
					<Media
						className="h-[75px] sm:h-[125px] rounded-lg pt-2 px-2"
						type={thumbnail.data.type}
						data={thumbnail.data.source}
						kind={thumbnail.data.kind}
						alt="thumbnail"
						autoPlay
						playsInline
						loop
						muted
					/>
				) : (
					<div className="flex h-[75px] sm:h-[100px] items-center justify-center" />
				)}
			</div>
			<div className="flex items-center justify-center py-2">
				<div className="text-sm font-bold text-center grow ml-12">
					{technique.data.name}
				</div>
				<Button
					id="edit-technique-from-system"
					variant="ghost"
					type="button"
					asChild
				>
					<Link
						to="/systems/$id/edit"
						params={{ id: systemId }}
						search={{
							techniqueId: technique.id,
						}}
					>
						<Pencil className="w-4 h-4" />
					</Link>
				</Button>
			</div>
		</div>
	);
}

function DeleteSystemButton({
	systemId,
}: {
	systemId: string;
}) {
	const { mutate: deleteSystem } = useDeleteSystem();
	const [openDialog, setOpenDialog] = useState(false);
	const navigate = useNavigate();

	return (
		<Dialog open={openDialog} onOpenChange={setOpenDialog}>
			<DialogTrigger asChild>
				<Button id="delete-system" variant="ghost">
					<Trash2 className="w-4 h-4" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Delete System</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					Are you sure you want to delete this system?
				</DialogDescription>
				<DialogFooter>
					<Button
						id="confirm-delete-system"
						variant="destructive"
						onClick={() =>
							deleteSystem(systemId, {
								onSuccess: () => {
									setOpenDialog(false);
									navigate({ to: "/systems" });
								},
							})
						}
					>
						Delete
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
