import { Center } from "@/components/ui/center";
import { useAuth } from "@clerk/clerk-react";
import { Navigate, Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth")({
	component: Layout,
});

function Layout() {
	const { isLoaded, isSignedIn } = useAuth();

	if (!isLoaded) {
		return null;
	}

	if (isSignedIn) {
		return <Navigate to="/" />;
	}

	return (
		<Center>
			<Outlet />
		</Center>
	);
}
