import { useGetConfig } from "@/hooks/use-config";
import { useEffect, useState } from "react";

/**
 * Strip any default themeing and apply the theme from the config
 * We don't render anything until the theme is mounted to avoid a flash of unstyled content
 */
export function ThemeProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const { data: config } = useGetConfig();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (!config) return;

		const root = window.document.documentElement;
		root.classList.remove("light", "dark");
		root.classList.add(config.theme.mode);

		const body = window.document.body;
		for (const className of body.classList.values()) {
			if (className.includes("theme-")) {
				body.classList.remove(className);
			}
		}
		body.classList.add(`theme-${config.theme.color}`);
		setMounted(true);
	}, [config]);

	if (!mounted) return null;

	return <>{children}</>;
}
