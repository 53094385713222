import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Spinner } from "@/components/ui/spinner";
import {
	useBillingPortal,
	useCheckout,
	useSubscriptionInfo,
} from "@/hooks/use-subscription";
import { useAuth, useUser } from "@clerk/clerk-react";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import { CreditCard, LogOut, ReceiptText } from "lucide-react";

export const Route = createFileRoute("/profile")({
	component: Profile,
});

function Profile() {
	const { isLoaded, isSignedIn } = useUser();

	if (!isLoaded) {
		return null;
	}

	if (!isSignedIn) {
		return <Navigate to="/signin" />;
	}

	return (
		<div className="container max-w-xl my-10">
			<AccountCard />
			<div className="mt-10" />
			<SubscriptionCard />
		</div>
	);
}

function AccountCard() {
	const { user } = useUser();
	const { signOut } = useAuth();
	return (
		<Card>
			<CardHeader>
				<CardTitle>Account</CardTitle>
				<CardDescription>Manage your account</CardDescription>
			</CardHeader>
			<CardContent>
				<div className="flex flex-col items-center justify-center space-y-2">
					<div className="flex w-full">
						<span className="grow">Email: </span>
						<span className="">{user?.primaryEmailAddress?.emailAddress}</span>
					</div>
					<div className="flex w-full">
						<span className="grow">Password: </span>
						<span className="">
							<span className="uppercase">********</span>
						</span>
					</div>
				</div>
				<Button
					id="sign-out"
					variant="default"
					className="w-full mt-10"
					onClick={() => signOut()}
				>
					<LogOut className="w-4 h-4 mr-2" />
					Sign out
				</Button>
			</CardContent>
		</Card>
	);
}

function SubscriptionCard() {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Subscription</CardTitle>
				<CardDescription>Manage your subscription</CardDescription>
			</CardHeader>
			<CardContent>
				<SubscriptionDetails />
			</CardContent>
		</Card>
	);
}

/**
 * scenarios:
 * no subscription or fully canceled -> subscribe button
 * active with cancelAtPeriodEnd true -> display canceled but allow them to renew subscription
 * other invalid status -> manage subscription button
 * active with cancelAtPeriodEnd false -> display status with full payment info and ability to manage subscription
 */
function SubscriptionDetails() {
	const { mutate: checkout, isPending: isCheckoutPending } = useCheckout();
	const { mutate: billingPortal, isPending: isBillingPending } =
		useBillingPortal();
	const { data: subscription, isPending, isError } = useSubscriptionInfo();

	if (isPending) {
		return (
			<div className="flex items-center justify-center">
				<Spinner />
			</div>
		);
	}

	if (isError) {
		return (
			<div className="flex items-center justify-center">
				<ErrorAlert
					title="Failed to load subscription info"
					description="Please try again."
				/>
			</div>
		);
	}

	if (!subscription || subscription.status === "canceled") {
		return (
			<>
				<Button
					id="subscribe"
					className="w-full"
					onClick={() => checkout()}
					disabled={isCheckoutPending}
				>
					{isCheckoutPending ? (
						<Spinner />
					) : (
						<>
							<CreditCard className="w-4 h-4 mr-2" />
							Subscribe
						</>
					)}
				</Button>
			</>
		);
	}

	if (subscription.status !== "active") {
		return (
			<>
				<div className="flex w-full">
					<span className="grow">Status: </span>
					<Badge variant="destructive">{getStatus(subscription.status)}</Badge>
				</div>
				<Button
					id="manage-inactive-subscription"
					className="w-full mt-5"
					onClick={() => billingPortal()}
					disabled={isBillingPending}
				>
					{isBillingPending ? (
						<Spinner />
					) : (
						<>
							<ReceiptText className="w-4 h-4 mr-2" />
							Manage Subscription
						</>
					)}
				</Button>
			</>
		);
	}

	if (subscription.status === "active" && subscription.cancelAtPeriodEnd) {
		return (
			<>
				<div className="flex flex-col items-center justify-center space-y-2">
					<div className="flex w-full">
						<span className="grow">Status: </span>
						<Badge variant="default">Canceled</Badge>
					</div>
					<div className="flex w-full">
						<span className="grow">Access Ends: </span>
						<span className="ml-2">
							{new Date(
								subscription.currentPeriodEnd * 1000,
							).toLocaleDateString()}
						</span>
					</div>
				</div>
				<Button
					id="manage-canceled-subscription"
					className="w-full mt-5"
					onClick={() => billingPortal()}
					disabled={isBillingPending}
				>
					{isBillingPending ? (
						<Spinner />
					) : (
						<>
							<ReceiptText className="w-4 h-4 mr-2" />
							Manage Subscription
						</>
					)}
				</Button>
			</>
		);
	}

	return (
		<>
			<div className="flex flex-col items-center justify-center space-y-2">
				<div className="flex w-full">
					<span className="grow">Status: </span>
					<Badge variant="default">Active</Badge>
				</div>
				<div className="flex w-full">
					<span className="grow">Payment Method: </span>
					<span className="ml-2">
						<span className="uppercase">{subscription.paymentMethodBrand}</span>
						{" ****"}
						{subscription.paymentMethodLast4}
					</span>
				</div>
				<div className="flex w-full">
					<span className="grow">Payment Amount: </span>
					<span className="ml-2">
						<span className="uppercase">{subscription.currency} </span>
						{new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: subscription.currency,
						}).format(subscription.amount / 100)}
					</span>
				</div>
				<div className="flex w-full">
					<span className="grow">Next Payment Date: </span>
					<span className="ml-2">
						{new Date(
							subscription.currentPeriodEnd * 1000,
						).toLocaleDateString()}
					</span>
				</div>
			</div>
			<Button
				id="manage-active-subscription"
				className="w-full mt-10"
				onClick={() => billingPortal()}
				disabled={isBillingPending}
			>
				{isBillingPending ? (
					<Spinner />
				) : (
					<>
						<ReceiptText className="w-4 h-4 mr-2" />
						Manage Subscription
					</>
				)}
			</Button>
		</>
	);
}

function getStatus(status: string) {
	switch (status) {
		case "active":
			return "Active";
		case "canceled":
			return "Canceled";
		case "incomplete":
			return "Incomplete";
		case "incomplete_expired":
			return "Incomplete Expired";
		case "unpaid":
			return "Unpaid";
		case "past_due":
			return "Past Due";
		case "trialing":
			return "Trialing";
		case "paused":
			return "Paused";
		default:
			return "Invalid";
	}
}
