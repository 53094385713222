import { getFFmpegModule, putFFmpegModule } from "@/async/db";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { nanoid } from "nanoid";
import {
	LogEvent,
	ProgressEvent,
} from "node_modules/@ffmpeg/ffmpeg/dist/esm/types";

const FFMPEG_CORE_MT_VERSION = "0.12.6";
const FFMPEG_CORE_MT_BASE_URL = `https://unpkg.com/@ffmpeg/core@${FFMPEG_CORE_MT_VERSION}/dist/esm`;

export async function loadFFmpeg() {
	const ffmpeg = new FFmpeg();
	const [coreURL, wasmURL] = await Promise.all([
		fetchModule("ffmpeg-core.js", "text/javascript"),
		fetchModule("ffmpeg-core.wasm", "application/wasm"),
	]);
	await ffmpeg.load({
		coreURL,
		wasmURL,
	});
	return ffmpeg;
}

// ffmpeg modules are upwards of 30mb, so we cache them in indexeddb
async function fetchModule(module: string, mimeType: string) {
	const url = `${FFMPEG_CORE_MT_BASE_URL}/${module}`;
	const cached_module = await getFFmpegModule(url);
	if (cached_module) {
		return URL.createObjectURL(cached_module);
	}
	const response = await fetch(url);
	if (!response.ok) {
		throw new Error(`Failed to fetch file: ${response.statusText}`);
	}
	const buff = await response.arrayBuffer();
	const blob = new Blob([buff], { type: mimeType });

	await putFFmpegModule(url, blob);

	return URL.createObjectURL(blob);
}

export type Description = {
	duration: number;
};

export async function describeFile({
	ffmpeg,
	input,
}: {
	ffmpeg: FFmpeg;
	input: File;
}): Promise<Description> {
	const inputFileId = `${nanoid()}`;
	await ffmpeg.writeFile(inputFileId, await fetchFile(input));
	let duration = 0;
	const logCallback = ({ message }: LogEvent) => {
		const match = message.match(/Duration: (\d{2}:\d{2}:\d{2}\.\d{2})/);
		if (match) {
			const [hours, minutes, seconds] = match[1].split(":").map(Number);
			duration = hours * 3600 + minutes * 60 + seconds;
		}
	};
	ffmpeg.on("log", logCallback);
	await ffmpeg.exec([
		"-fflags",
		"+fastseek",
		"-ss",
		"86400", // seek to 24 hours to avoid actually processing the video
		"-i",
		inputFileId,
		"-f",
		"null",
		"-",
	]);
	ffmpeg.off("log", logCallback);
	await ffmpeg.deleteFile(inputFileId);
	return { duration };
}

export type Output = {
	blob: Blob;
	type: "video" | "image";
	ext: string;
	origin: string;
	start?: string;
	aspectRatio: number;
};

export async function clipVideo({
	ffmpeg,
	input,
	start,
	duration,
	durationSeconds,
	onProgress,
}: {
	ffmpeg: FFmpeg;
	input: File;
	start: string;
	duration: string;
	durationSeconds: number;
	onProgress: (progress: number) => void;
}): Promise<Output> {
	const progressCallback = ({ time }: ProgressEvent) => {
		onProgress((time / 1000000 / durationSeconds) * 100);
	};
	let aspectRatio = 0;
	const logCallback = ({ message }: LogEvent) => {
		const match = message.match(/DAR (\d+:\d+)/);
		if (match) {
			aspectRatio = match[1]
				.split(":")
				.map(Number)
				.reduce((a, b) => a / b);
		}
	};
	ffmpeg.on("progress", progressCallback);
	ffmpeg.on("log", logCallback);

	const inputFileId = `${nanoid()}`;
	const outputFileId = `${nanoid()}.mp4`;

	await ffmpeg.writeFile(inputFileId, await fetchFile(input));
	await ffmpeg.exec([
		"-fflags",
		"+fastseek",
		"-ss",
		start,
		"-i",
		inputFileId,
		"-t",
		duration,
		"-c:v",
		"libx264",
		"-an",
		"-vf",
		"scale=640:480:force_original_aspect_ratio=decrease", // downscale but keep aspect ratio
		"-b:v",
		"500k",
		"-loglevel",
		"verbose",
		outputFileId,
	]);
	const data = (await ffmpeg.readFile(outputFileId)) as Uint8Array;
	const blob = new Blob([data.buffer], { type: "video/mp4" });

	await ffmpeg.deleteFile(inputFileId);
	await ffmpeg.deleteFile(outputFileId);

	ffmpeg.off("progress", progressCallback);
	ffmpeg.off("log", logCallback);

	return {
		blob,
		type: "video",
		ext: "mp4",
		origin: input.name,
		start,
		aspectRatio,
	};
}

export async function convertMedia({
	ffmpeg,
	input,
	onProgress,
}: {
	ffmpeg: FFmpeg;
	input: File;
	onProgress: (progress: number) => void;
}): Promise<Output> {
	const inputType = input.type.split("/")[0];
	const inputExt = input.type.split("/")[1];
	if (inputType !== "image" && inputType !== "video") {
		throw new Error("Invalid file type");
	}
	const inputFileId = `${nanoid()}.${inputExt}`;
	await ffmpeg.writeFile(inputFileId, await fetchFile(input));
	let aspectRatio = 0;
	const logCallback = ({ message }: LogEvent) => {
		const match = message.match(/DAR (\d+:\d+)/);
		if (match) {
			aspectRatio = match[1]
				.split(":")
				.map(Number)
				.reduce((a, b) => a / b);
		}
	};
	const progressCallback = ({ progress }: ProgressEvent) => {
		onProgress(progress * 100);
	};
	ffmpeg.on("log", logCallback);
	ffmpeg.on("progress", progressCallback);
	const ext = inputType === "image" ? "webp" : "mp4";
	const outputFileId = `${nanoid()}.${ext}`;
	if (inputType === "image") {
		await ffmpeg.exec([
			"-i",
			inputFileId,
			"-c:v",
			"libwebp",
			"-lossless",
			"0",
			"-q:v",
			"80",
			"-compression_level",
			"6",
			"-loglevel",
			"verbose",
			outputFileId,
		]);
	} else {
		await ffmpeg.exec([
			"-i",
			inputFileId,
			"-c:v",
			"libx264",
			"-an",
			"-vf",
			"scale=640:480:force_original_aspect_ratio=decrease", // downscale but keep aspect ratio
			"-b:v",
			"500k",
			"-loglevel",
			"verbose",
			outputFileId,
		]);
	}
	const data = (await ffmpeg.readFile(outputFileId)) as Uint8Array;
	const blob = new Blob([data.buffer], { type: `${inputType}/${ext}` });

	await ffmpeg.deleteFile(inputFileId);
	await ffmpeg.deleteFile(outputFileId);

	ffmpeg.off("log", logCallback);
	ffmpeg.off("progress", progressCallback);

	return {
		blob,
		type: inputType,
		ext,
		origin: input.name,
		aspectRatio,
	};
}
