import {
	loadFFmpeg,
	clipVideo,
	describeFile,
	Output,
	convertMedia,
} from "@/async/ffmpeg";
import { useToast } from "@/components/ui/use-toast";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useLoadFFmpeg() {
	return useQuery({
		queryKey: ["ffmpeg"],
		queryFn: loadFFmpeg,
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
}

export function useDescribeFile() {
	const queryClient = useQueryClient();
	const ffmpeg = queryClient.getQueryData(["ffmpeg"]) as FFmpeg;
	return useMutation({
		mutationFn: async ({ input }: { input: File }) =>
			describeFile({ ffmpeg, input }),
	});
}

export function useClipVideo({
	onSuccess,
	onProgress,
}: {
	onSuccess: (output: Output) => void;
	onProgress: (progress: number) => void;
}) {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const ffmpeg = queryClient.getQueryData(["ffmpeg"]) as FFmpeg;
	return useMutation({
		mutationFn: async ({
			input,
			start,
			duration,
			durationSeconds,
		}: {
			input: File;
			start: string;
			duration: string;
			durationSeconds: number;
		}) =>
			clipVideo({
				ffmpeg,
				input,
				start,
				duration,
				durationSeconds,
				onProgress,
			}),
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error creating clip",
				description: `${error}`,
			});
		},
		onSuccess,
	});
}

export function useConvertMedia({
	onSuccess,
	onProgress,
}: {
	onSuccess: (output: Output) => void;
	onProgress: (progress: number) => void;
}) {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const ffmpeg = queryClient.getQueryData(["ffmpeg"]) as FFmpeg;
	return useMutation({
		mutationFn: async ({
			input,
		}: {
			input: File;
		}) =>
			convertMedia({
				ffmpeg,
				input,
				onProgress,
			}),
		onError: (error) => {
			toast({
				variant: "destructive",
				title: "Error converting media",
				description: `${error}`,
			});
		},
		onSuccess,
	});
}
