import { Outlet, createRootRoute } from "@tanstack/react-router";
import {
	Box,
	LogIn,
	MoreVertical,
	Settings,
	User,
	Waypoints,
} from "lucide-react";
import { cn } from "@/components/lib/utils";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { Link } from "@tanstack/react-router";
import { useAuth } from "@clerk/clerk-react";
import { Spinner } from "@/components/ui/spinner";
import { useToast } from "@/components/ui/use-toast";
import { useUser } from "@/hooks/use-user";
import { useEffect } from "react";
import { DiscordIcon } from "@/components/icons/discord";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { z } from "zod";

const rootSchema = z.object({
	success: z.boolean().optional(),
	canceled: z.boolean().optional(),
});

export const Route = createRootRoute({
	component: Root,
	validateSearch: rootSchema,
});

export default function Root() {
	const { isSignedIn } = useAuth();
	const { data: user, isPending, isError } = useUser();
	const { toast } = useToast();
	const { success } = Route.useSearch();

	useEffect(() => {
		if (isPending || isError) {
			return;
		}
		if (success) {
			toast({
				title: "Payment successful",
				description: "Thank you for subscribing",
			});
		} else if (isSignedIn && !user?.active) {
			toast({
				variant: "destructive",
				title: "You do not have an active subscription",
				description: "You are in trial mode",
			});
		}
	}, [isSignedIn, user, toast, isPending, isError, success]);

	return (
		<div className="flex flex-col min-h-screen w-full bg-secondary">
			<aside className="fixed inset-y-0 left-0 w-14 flex-col border-r bg-background hidden sm:flex">
				<nav className="flex flex-col items-center gap-4 px-2 pt-5">
					<Nav to="/systems" Icon={Waypoints} title="Systems" />
					<Nav to="/techniques" Icon={Box} title="Techniques" />
				</nav>
				<nav className="mt-auto flex flex-col items-center gap-4 px-2 pb-5">
					<UserNav />
					<Nav to="/settings" Icon={Settings} title="Settings" />
					<Nav
						to={import.meta.env.VITE_DISCORD_INVITE_LINK}
						target="_blank"
						Icon={DiscordIcon}
						iconClassName="fill-current"
						title="Discord"
					/>
				</nav>
			</aside>
			<main className="mb-14 sm:mb-0 sm:ml-14">
				<Outlet />
			</main>
			<aside className="fixed inset-x-0 bottom-0 border-t bg-background sm:hidden">
				<nav className="flex h-14 items-center justify-between px-4 ">
					<Nav to="/systems" Icon={Waypoints} title="Systems" isMobile />
					<Nav to="/techniques" Icon={Box} title="Techniques" isMobile />
					<UserNav isMobile />
					<MoreDropdown />
				</nav>
			</aside>
		</div>
	);
}

function UserNav({ isMobile }: { isMobile?: boolean }) {
	const { userId, isLoaded } = useAuth();

	if (!isLoaded) {
		return <Spinner className="h-5 w-5 m-2" />;
	}

	if (!userId) {
		return <Nav to="/signup" Icon={LogIn} title="Signup" isMobile={isMobile} />;
	}

	return <Nav to="/profile" Icon={User} title="Profile" isMobile={isMobile} />;
}

function MoreDropdown() {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="px-1">
					<MoreVertical className="h-5 w-5 text-muted-foreground" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="">
				<DropdownMenuItem asChild>
					<Link
						to="/settings"
						className={cn(
							"flex items-center justify-center text-muted-foreground",
						)}
					>
						<Settings className="mr-2 h-5 w-5" />
						<span className="">Settings</span>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem asChild>
					<Link
						to={import.meta.env.VITE_DISCORD_INVITE_LINK}
						target="_blank"
						className={cn(
							"flex items-center justify-center text-muted-foreground",
						)}
					>
						<DiscordIcon className="mr-2 h-5 w-5 fill-current" />
						<span className="">Discord</span>
					</Link>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

function Nav({
	to,
	Icon,
	title,
	iconClassName,
	target,
	isMobile,
}: {
	title: string;
	Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
	iconClassName?: string;
	to: string;
	target?: string;
	isMobile?: boolean;
}) {
	if (isMobile) {
		return (
			<Link
				key={title}
				to={to}
				target={target}
				className={cn(
					"flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8",
				)}
			>
				<Icon className={cn("h-5 w-5", iconClassName)} />
				<span className="sr-only">{title}</span>
			</Link>
		);
	}

	return (
		<Tooltip key={title} delayDuration={0}>
			<TooltipTrigger asChild>
				<Link
					key={title}
					to={to}
					target={target}
					className={cn(
						"flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8",
					)}
				>
					<Icon className={cn("h-5 w-5", iconClassName)} />
					<span className="sr-only">{title}</span>
				</Link>
			</TooltipTrigger>
			<TooltipContent side="right">{title}</TooltipContent>
		</Tooltip>
	);
}
