import { getUser } from "@/async/api";
import { useAuth } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";

export function useUser() {
	const { getToken, userId } = useAuth();
	return useQuery({
		queryKey: ["users", userId],
		queryFn: async () => {
			const token = await getToken();
			if (!token || !userId) {
				return null;
			}
			return getUser(token);
		},
		refetchOnMount: false,
	});
}
