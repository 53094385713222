import { createFileRoute } from "@tanstack/react-router";
import { CheckIcon, MoonIcon, SunIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/components/lib/utils";
import { Label } from "@/components/ui/label";
import { useGetConfig, usePutConfig } from "@/hooks/use-config";

export const COLORS = [
	{
		name: "zinc",
		label: "Zinc",
		activeColor: {
			light: "240 5.9% 10%",
			dark: "240 5.2% 33.9%",
		},
	},
	{
		name: "slate",
		label: "Slate",
		activeColor: {
			light: "215.4 16.3% 46.9%",
			dark: "215.3 19.3% 34.5%",
		},
	},
	{
		name: "stone",
		label: "Stone",
		activeColor: {
			light: "25 5.3% 44.7%",
			dark: "33.3 5.5% 32.4%",
		},
	},
	{
		name: "gray",
		label: "Gray",
		activeColor: {
			light: "220 8.9% 46.1%",
			dark: "215 13.8% 34.1%",
		},
	},
	{
		name: "neutral",
		label: "Neutral",
		activeColor: {
			light: "0 0% 45.1%",
			dark: "0 0% 32.2%",
		},
	},
	{
		name: "red",
		label: "Red",
		activeColor: {
			light: "0 72.2% 50.6%",
			dark: "0 72.2% 50.6%",
		},
	},
	{
		name: "rose",
		label: "Rose",
		activeColor: {
			light: "346.8 77.2% 49.8%",
			dark: "346.8 77.2% 49.8%",
		},
	},
	{
		name: "orange",
		label: "Orange",
		activeColor: {
			light: "24.6 95% 53.1%",
			dark: "20.5 90.2% 48.2%",
		},
	},
	{
		name: "green",
		label: "Green",
		activeColor: {
			light: "142.1 76.2% 36.3%",
			dark: "142.1 70.6% 45.3%",
		},
	},
	{
		name: "blue",
		label: "Blue",
		activeColor: {
			light: "221.2 83.2% 53.3%",
			dark: "217.2 91.2% 59.8%",
		},
	},
	{
		name: "yellow",
		label: "Yellow",
		activeColor: {
			light: "47.9 95.8% 53.1%",
			dark: "47.9 95.8% 53.1%",
		},
	},
	{
		name: "violet",
		label: "Violet",
		activeColor: {
			light: "262.1 83.3% 57.8%",
			dark: "263.4 70% 50.4%",
		},
	},
];

export const Route = createFileRoute("/settings")({
	component: Settings,
});

function Settings() {
	const { data: config } = useGetConfig();
	const { mutate: setConfig } = usePutConfig();

	if (!config) return null;

	return (
		<div className="container max-w-4xl my-10 flex flex-1 flex-col space-y-4 md:space-y-6">
			<div className="space-y-1.5">
				<Label className="text-sm font-semibold">Mode</Label>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-2">
					<>
						<Button
							id="light-mode"
							variant={"outline"}
							size="sm"
							onClick={() =>
								setConfig({
									...config,
									theme: { ...config.theme, mode: "light" },
								})
							}
							className={cn(
								config.theme.mode === "light" && "border-2 border-primary",
							)}
						>
							<SunIcon className="mr-1 -translate-x-1" />
							Light
						</Button>
						<Button
							id="dark-mode"
							variant={"outline"}
							size="sm"
							onClick={() =>
								setConfig({
									...config,
									theme: { ...config.theme, mode: "dark" },
								})
							}
							className={cn(
								config.theme.mode === "dark" && "border-2 border-primary",
							)}
						>
							<MoonIcon className="mr-1 -translate-x-1" />
							Dark
						</Button>
					</>
				</div>
			</div>
			<div className="space-y-1.5">
				<Label className="text-sm font-semibold">Color</Label>
				<div className="grid grid-cols-2 md:grid-cols-3 gap-2">
					{COLORS.map((color) => {
						const isActive = color.name === config.theme.color;
						return (
							<Button
								id={`color-${color.name}`}
								variant={"outline"}
								size="sm"
								key={color.name}
								onClick={() => {
									setConfig({
										...config,
										theme: { ...config.theme, color: color.name },
									});
								}}
								className={cn(
									"justify-start",
									isActive && "border-2 border-primary",
								)}
								style={
									{
										"--theme-primary": `hsl(${
											color?.activeColor[
												config.theme.mode === "dark" ? "dark" : "light"
											]
										})`,
									} as React.CSSProperties
								}
							>
								<span
									className={cn(
										"mr-1 flex h-5 w-5 shrink-0 -translate-x-1 items-center justify-center rounded-full bg-[--theme-primary]",
									)}
								>
									{isActive && <CheckIcon className="h-4 w-4 text-white" />}
								</span>
								{color.label}
							</Button>
						);
					})}
				</div>
			</div>
		</div>
	);
}
