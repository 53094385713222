import {
	getResources,
	getResource,
	newResource,
	putResource,
	deleteResource,
} from "@/async/db";
import { Media, Technique, TechniqueData } from "@/hooks/types";
import { useSyncResources } from "@/hooks/use-sync";
import { useWorker } from "@/hooks/use-worker";
import { MessageKind } from "@/worker/worker";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";

export function useGetTechniques({
	associatedSystem,
}: { associatedSystem: string | null }) {
	const { userId } = useAuth();
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["techniques"],
		queryFn: async (): Promise<Technique[]> => {
			const techniques: Technique[] = await getResources("technique");
			return techniques
				.filter(
					(technique) =>
						(technique.userId === userId || !technique.userId) && // user based filtering
						!technique.deleted && // deletion based filtering
						technique.data.associatedSystem === associatedSystem, // system based filtering
				)
				.sort((t1, t2) => t2.data.createdAt - t1.data.createdAt);
		},
		enabled: synced,
	});
}

export function useGetTechnique(id: string) {
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["techniques", id],
		queryFn: async (): Promise<Technique | null> => getResource(id),
		enabled: synced,
	});
}

export function useGetTechniqueThumbnail(id: string) {
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["techniques", id, "thumbnail"],
		queryFn: async (): Promise<Media | null> => {
			const technique: Technique | null = await getResource(id);
			if (!technique || technique.data.media.length === 0) {
				return null;
			}
			return getResource(technique.data.media[0].id);
		},
		enabled: synced,
	});
}

export function useNewTechnique() {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async ({
			associatedSystem,
		}: { associatedSystem: string | null }) =>
			newResource("technique", nanoid(), {
				name: "Unnamed Technique",
				notes: "",
				media: [],
				createdAt: Date.now(),
				associatedSystem,
			} as TechniqueData),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["techniques"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function usePutTechnique(id: string) {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (data: TechniqueData) => putResource(id, data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["techniques"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useDeleteTechnique() {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (id: string) => deleteResource(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["techniques"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}
