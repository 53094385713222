import { openDB, DBSchema, IDBPDatabase, IDBPTransaction } from "idb";

const DB_NAME = "doju";
const DB_VERSION = 4;

type ConfigSchema = {
	theme: ThemeSchema;
};

type ThemeSchema = {
	mode: "dark" | "light";
	color: string;
};

type ResourceSchema = {
	id: string;
	updatedAt: string | null;
	userId: string | null;
	dirty: boolean;
	deleted: boolean;
	shared: boolean;
	kind: "system" | "technique" | "media";
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: any;
};

interface ResourceSyncSchema {
	updatedAt: string | null;
	userId: string;
}

interface LockSchema {
	id: string;
	locked: boolean;
}

interface DB extends DBSchema {
	config: {
		key: number;
		value: ConfigSchema;
	};
	ffmpeg_modules: {
		key: string;
		value: Blob;
	};
	resources: {
		key: string;
		value: ResourceSchema;
		indexes: { kind: string };
	};
	resource_sync: {
		key: string;
		value: ResourceSyncSchema;
	};
	locks: {
		key: string;
		value: LockSchema;
	};
}

const db = openDB<DB>(DB_NAME, DB_VERSION, {
	upgrade,
});

async function getDB() {
	return db;
}

async function upgrade(
	db: IDBPDatabase<DB>,
	oldVersion: number,
	_newVersion: number | null,
	tx: IDBPTransaction<
		DB,
		("config" | "ffmpeg_modules" | "resources" | "resource_sync")[],
		"versionchange"
	>,
) {
	try {
		const migrations = [
			async () => {
				db.createObjectStore("config");
				db.createObjectStore("ffmpeg_modules");
				db.createObjectStore("resources", { keyPath: "id" }).createIndex(
					"kind",
					"kind",
				);
				db.createObjectStore("resource_sync", {
					keyPath: "userId",
				});
			},
			async () => {
				const resources = await tx.objectStore("resources").getAll();
				for (const resource of resources) {
					resource.shared = false;
				}
				for (const resource of resources) {
					await tx.objectStore("resources").put(resource);
				}
			},
			async () => {
				const resources = await tx.objectStore("resources").getAll();
				const techniques = resources.filter(
					(resource) => resource.kind === "technique",
				);
				for (const technique of techniques) {
					technique.data.associatedSystem = null;
					technique.dirty = true;
					await tx.objectStore("resources").put(technique);
				}
			},
			async () => {
				db.createObjectStore("locks", { keyPath: "id" });
			},
		];

		const newMigrations = migrations.slice(oldVersion);

		console.log("Running migrations", newMigrations);

		for (const migration of newMigrations) {
			await migration();
		}
	} catch (ex) {
		console.error("Failed to run migrations", ex);
	}
}

export async function getConfig(): Promise<ConfigSchema | null> {
	const db = await getDB();
	const config = await db.get("config", 1);
	return config ?? null;
}

export async function putConfig(config: ConfigSchema): Promise<void> {
	const db = await getDB();
	await db.put("config", config, 1);
}

export async function getFFmpegModule(url: string): Promise<Blob | null> {
	const db = await getDB();
	const module = await db.get("ffmpeg_modules", url);
	return module ?? null;
}

export async function putFFmpegModule(url: string, blob: Blob): Promise<void> {
	const db = await getDB();
	await db.put("ffmpeg_modules", blob, url);
}

export async function getDirtyResources(
	userId: string,
): Promise<ResourceSchema[]> {
	const db = await getDB();
	const resources = await db.getAll("resources");
	return resources.filter(
		(resource) =>
			resource.dirty &&
			(resource.userId === userId || resource.userId === null),
	);
}

export async function getLastUpdatedAt(userId: string): Promise<string | null> {
	const db = await getDB();
	const sync = await db.get("resource_sync", userId);
	if (!sync) {
		return null;
	}
	return sync.updatedAt;
}

export async function setResourceNotDirty(id: string): Promise<void> {
	const db = await getDB();
	const tx = db.transaction("resources", "readwrite");
	const resource = await tx.store.get(id);
	if (!resource) {
		throw new Error(`Resource with id ${id} not found`);
	}
	resource.dirty = false;
	await tx.store.put(resource);
	await tx.done;
}

export async function putResources(
	userId: string,
	updatedAt: string,
	resources: ResourceSchema[],
): Promise<void> {
	const db = await getDB();
	const tx = db.transaction(["resources", "resource_sync"], "readwrite");
	for (const resource of resources) {
		const existingResource = await tx.objectStore("resources").get(resource.id);
		// if the incoming resource is deleted we can safely delete the local copy if it exists
		// NOTE: with react strict more the sync call runs twice and this appears like it doesn't work
		// This is probably fine to let it happen in local and it shouldn't happen in production
		if (existingResource && resource.deleted) {
			console.log("deleting resource ", resource.id);
			await tx.objectStore("resources").delete(resource.id);
		}
		// the resource could have become dirty inbetween us sending it to the server and now
		// we don't wan't to overwrite the local changes as we will send them to the server again in the
		// next round of syncing
		else if (!existingResource || !existingResource.dirty) {
			console.log("putting resource ", resource.id);
			await tx.objectStore("resources").put(resource);
		} else {
			console.log("skipping local dirty resource ", resource.id);
		}
	}
	await tx.objectStore("resource_sync").put({
		userId,
		updatedAt,
	});
	await tx.done;
}

export async function getResources(
	kind: "system" | "technique" | "media",
): Promise<ResourceSchema[]> {
	const db = await getDB();
	return db.getAllFromIndex("resources", "kind", kind);
}

export async function getResource(id: string): Promise<ResourceSchema | null> {
	const db = await getDB();
	const resource = await db.get("resources", id);
	return resource ?? null;
}

export async function newResource(
	kind: "system" | "technique" | "media",
	id: string,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: any,
): Promise<string> {
	const db = await getDB();
	await db.put("resources", {
		id,
		kind,
		userId: null,
		updatedAt: null,
		dirty: true,
		deleted: false,
		shared: false,
		data,
	});
	return id;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export async function putResource(id: string, data: any): Promise<string> {
	const db = await getDB();
	const tx = db.transaction("resources", "readwrite");
	const existingResource = await tx.store.get(id);
	if (!existingResource) {
		throw new Error(`Resource with id ${id} not found`);
	}
	existingResource.dirty = true;
	existingResource.data = data;
	await tx.store.put(existingResource);
	await tx.done;
	return id;
}

export async function deleteResource(id: string): Promise<string> {
	const db = await getDB();
	const tx = db.transaction("resources", "readwrite");
	const resource = await tx.store.get(id);
	if (!resource) {
		throw new Error(`Resource with id ${id} not found`);
	}
	resource.deleted = true;
	resource.dirty = true;
	await tx.store.put(resource);
	await tx.done;
	return id;
}

export async function setResourceShared(
	id: string,
	shared: boolean,
): Promise<void> {
	const db = await getDB();
	const tx = db.transaction("resources", "readwrite");
	const resource = await tx.store.get(id);
	if (!resource) {
		throw new Error(`Resource with id ${id} not found`);
	}
	resource.shared = shared;
	await tx.store.put(resource);
	await tx.done;
}

export async function putSharedResource(resource: ResourceSchema) {
	const db = await getDB();
	const tx = db.transaction("resources", "readwrite");
	await tx.store.put(resource);
	await tx.done;
}

export async function acquireLock(id: string): Promise<string | null> {
	const db = await getDB();
	const tx = db.transaction("locks", "readwrite");
	const lock = await tx.store.get(id);
	if (lock?.locked) {
		console.log("failed to acquire lock for", id);
		return null;
	}
	await tx.store.put({ id, locked: true });
	await tx.done;
	console.log("acquired lock for", id);
	return id;
}

export async function releaseLock(id: string | null): Promise<void> {
	if (!id) {
		return;
	}
	const db = await getDB();
	await db.put("locks", { id, locked: false });
	console.log("released lock for", id);
}
