import { Link, createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useEmailSignIn } from "@/hooks/use-auth";
import { Spinner } from "@/components/ui/spinner";

export const Route = createFileRoute("/_auth/signin")({
	component: Signin,
});

export function Signin() {
	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const { mutate, isPending } = useEmailSignIn();

	return (
		<div className="flex-col w-full max-w-sm px-4">
			<div className="text-center text-2xl font-bold">Sign In</div>
			<div className="mt-2 text-center text-sm text-muted-foreground">
				Don&apos;t have an account?{" "}
				<Link to="/signup" className="underline text-primary">
					Sign up
				</Link>
			</div>
			<div className="mt-2 text-center text-sm text-muted-foreground">
				Forgot your password?{" "}
				<Link to="/forgot" className="underline text-primary">
					Reset it
				</Link>
			</div>
			<form
				className="mt-4"
				onSubmit={(e) => {
					e.preventDefault();
					mutate({ emailAddress, password });
				}}
			>
				<div className="grid gap-2 mt-4">
					<Label htmlFor="email">Email</Label>
					<Input
						id="email"
						type="email"
						placeholder="m@example.com"
						required
						autoCapitalize="none"
						autoComplete="email"
						autoCorrect="off"
						disabled={isPending}
						value={emailAddress}
						onChange={(e) => setEmailAddress(e.target.value)}
					/>
				</div>
				<div className="grid gap-2 mt-4">
					<Label htmlFor="password">Password</Label>
					<Input
						id="password"
						type="password"
						required
						autoCapitalize="none"
						autoComplete="current-password"
						autoCorrect="off"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						disabled={isPending}
					/>
				</div>
				<Button
					id="signin"
					className="w-full mt-6"
					type="submit"
					disabled={isPending || !emailAddress || !password}
				>
					{isPending && <Spinner className="mr-2" />} Sign in
				</Button>
			</form>
		</div>
	);
}
