import "@/globals.css";
import "@/themes.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouteProvider } from "@/providers/route-provider";
import { QueryProvider } from "@/providers/query-provider";
import { ThemeProvider } from "@/providers/theme-provider";
import { TooltipProvider } from "@/providers/tooltip-provider";
import { Toaster } from "@/components/ui/toaster";
import { AuthProvider } from "@/providers/auth-provider";
import { WorkerProvider } from "@/providers/worker-provider";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<QueryProvider>
			<ThemeProvider>
				<AuthProvider>
					<WorkerProvider>
						<TooltipProvider>
							<RouteProvider />
							<Toaster />
						</TooltipProvider>
					</WorkerProvider>
				</AuthProvider>
			</ThemeProvider>
		</QueryProvider>
	</React.StrictMode>,
);
