import { useState } from "react";
import { Button } from "@/components/ui/button";
import { InfoButton } from "@/components/ui/info-button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/spinner";
import { useToast } from "@/components/ui/use-toast";
import { Check, Circle, Scissors } from "lucide-react";
import {
	useLoadFFmpeg,
	useClipVideo,
	useDescribeFile,
} from "@/hooks/use-ffmpeg";
import { useNewMedia } from "@/hooks/use-media";
import { Output } from "@/async/ffmpeg";
import { Media } from "@/routes/techniques/components/media";
import { ErrorAlert } from "@/components/ui/error-alert";
import { TimestampInput } from "@/components/ui/timestamp-input";
import {
	formatTimestamp,
	parseSeconds,
} from "@/routes/techniques/components/format";

type Source = {
	file: File;
	duration: number;
};

// TODO: Add Restart button
export function VideoForm({
	techniqueId,
	onSuccess,
}: { techniqueId: string; onSuccess: () => void }) {
	const { isPending, isError, error } = useLoadFFmpeg();
	const [source, setSource] = useState<Source | null>(null);
	const [clip, setClip] = useState<Output | null>(null);

	if (isPending) {
		return (
			<div className="flex justify-center items-center">
				<Spinner className="w-8 h-8" />
			</div>
		);
	}

	if (isError) {
		return (
			<ErrorAlert title="Error loading video assets" description={`${error}`} />
		);
	}

	return (
		<div className="flex flex-col space-y-5">
			<div className="flex space-x-3 items-center">
				{source ? (
					<Check className="w-4 h-4" />
				) : (
					<Circle className="w-4 h-4" />
				)}
				<Label className="text-md">Select instructional to clip</Label>
				<InfoButton id="select-instr-info">
					Select the video file to extract a clip from it. You can usually
					download the instructional video from the website you purchased it at.
					Max file size: 2GB
				</InfoButton>
			</div>
			{!source ? (
				<ChooseSourceStep setSource={setSource} />
			) : (
				<>
					<div className="flex space-x-3 items-center">
						{clip ? (
							<Check className="w-4 h-4" />
						) : (
							<Circle className="w-4 h-4" />
						)}
						<Label className="text-md">Generate clip</Label>
						<InfoButton id="generate-clip-info">
							Clip duration must be a maximum of 60 seconds. Clipping videos can
							take a while depending on the duration of the clip and the specs
							of your machine.
						</InfoButton>
					</div>
					{!clip ? (
						<GenerateClipStep source={source} setClip={setClip} />
					) : (
						<>
							<div className="flex space-x-3 items-center">
								<Circle className="w-4 h-4" />
								<Label className="text-md">Confirm details</Label>
							</div>
							<ConfirmStep
								techniqueId={techniqueId}
								clip={clip}
								onSuccess={onSuccess}
							/>
						</>
					)}
				</>
			)}
		</div>
	);
}

function ChooseSourceStep({
	setSource,
}: { setSource: (source: Source) => void }) {
	const { toast } = useToast();
	const { mutate: describeFile, isPending } = useDescribeFile();

	return (
		<>
			<Input
				id="source"
				type="file"
				accept="video/*"
				disabled={isPending}
				onChange={(event) => {
					if (!event.target.files || event.target.files.length === 0) {
						toast({
							variant: "destructive",
							title: "No file selected",
							description: "Please select a video file to extract clips from",
						});
						return;
					}
					const file = event.target.files[0];
					describeFile(
						{ input: file },
						{
							onSuccess: ({ duration }) => {
								setSource({ file, duration });
							},
						},
					);
				}}
			/>
			{isPending && (
				<div className="flex mt-2 justify-center items-center">
					<Spinner className="w-5 h-5" />
				</div>
			)}
		</>
	);
}

function GenerateClipStep({
	source,
	setClip,
}: { source: Source; setClip: (clip: Output) => void }) {
	const [start, setStart] = useState("");
	const [end, setEnd] = useState("");
	const [progress, setProgress] = useState(0);
	const { toast } = useToast();
	const { mutate, isPending } = useClipVideo({
		onSuccess: (output) => {
			setClip(output);
		},
		onProgress: (progress) => {
			// progress seems to flash a high number when it starts
			if (progress > 100) {
				setProgress(0);
				return;
			}
			setProgress(progress);
		},
	});

	return (
		<div className="flex flex-col">
			<div className="mb-3">
				<Media
					data={source.file}
					kind="file"
					type="video"
					alt="preview"
					controls
				/>
			</div>
			<div className="mb-6">
				<Label className="text-md">Start timestamp</Label>
				<TimestampInput
					disabled={isPending}
					value={start}
					onChange={setStart}
				/>
			</div>
			<div className="mb-6">
				<Label className="text-md">End timestamp</Label>
				<TimestampInput disabled={isPending} value={end} onChange={setEnd} />
			</div>
			<Button
				id="clip-video"
				className="my-3"
				disabled={isPending}
				type="button"
				onClick={() => {
					const startSeconds = parseSeconds(start);
					const endSeconds = parseSeconds(end);
					if (startSeconds === undefined || endSeconds === undefined) {
						toast({
							variant: "destructive",
							title: "Invalid timestamp",
							description: "Please enter valid timestamps",
						});
						return;
					}
					if (endSeconds - startSeconds > 60) {
						toast({
							variant: "destructive",
							title: "Clip too long",
							description: "Clips must be a maximum of 60 seconds",
						});
						return;
					}
					if (endSeconds <= startSeconds) {
						toast({
							variant: "destructive",
							title: "Invalid clip",
							description: "End time must be after start time",
						});
						return;
					}
					if (endSeconds > source.duration) {
						toast({
							variant: "destructive",
							title: "Invalid clip",
							description: "End time cannot exceed video length",
						});
						return;
					}
					mutate({
						input: source.file,
						start: formatTimestamp(startSeconds),
						duration: formatTimestamp(endSeconds - startSeconds),
						durationSeconds: endSeconds - startSeconds,
					});
				}}
			>
				{isPending ? (
					<Spinner className="mr-2 h-4 w-4" />
				) : (
					<Scissors className="mr-2 w-4 h-4" />
				)}
				{!isPending ? "Clip" : `${Math.round(progress)}%`}
			</Button>
		</div>
	);
}

function ConfirmStep({
	techniqueId,
	clip,
	onSuccess,
}: {
	techniqueId: string;
	clip: Output;
	onSuccess: () => void;
}) {
	const { mutate: addMedia } = useNewMedia(techniqueId);

	return (
		<div className="flex flex-col">
			<Media
				data={clip.blob}
				kind="blob"
				type="video"
				alt="preview"
				autoPlay
				playsInline
				muted
				loop
				controls
			/>
			<Button
				id="confirm-video-clip"
				className="mt-6"
				onClick={() =>
					addMedia(
						{
							kind: "blob",
							source: clip.blob,
							type: clip.type,
							origin: `${clip.origin} @ ${clip.start}`,
							aspectRatio: clip.aspectRatio,
							ext: clip.ext,
						},
						{
							onSuccess,
						},
					)
				}
			>
				<Check className="mr-2 w-5 h-5" />
				Confirm
			</Button>
		</div>
	);
}
