import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Eye, Trash2 } from "lucide-react";
import { useDeleteTechnique, useGetTechnique } from "@/hooks/use-techniques";
import { Technique } from "@/routes/techniques/components/technique";
import { DialogHeader, DialogFooter } from "@/components/ui/dialog";
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogTitle,
	DialogDescription,
} from "@/components/ui/dialog";
import { useState } from "react";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Center } from "@/components/ui/center";
import { Spinner } from "@/components/ui/spinner";

export const Route = createFileRoute("/techniques/$id/edit")({
	component: EditTechnique,
});

function EditTechnique() {
	const { id } = Route.useParams();
	const { isPending, isError } = useGetTechnique(id);

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the technique at this time."
				/>
			</Center>
		);
	}

	return (
		<>
			<div className="flex mx-3 sm:mx-5 mt-3 justify-center items-center">
				<Button id="edit-technique-back" variant="ghost" asChild>
					<Link to="/techniques" params={{ id }}>
						<ChevronLeft className="w-4 h-4" />
					</Link>
				</Button>
				<div className="grow" />
				<Button
					id="edit-technique-view"
					className=""
					variant="ghost"
					type="button"
					asChild
				>
					<Link to="/techniques/$id" params={{ id }}>
						<Eye className="w-4 h-4" />
					</Link>
				</Button>
				<DeleteTechniqueButton techniqueId={id} />
			</div>
			<div className="container max-w-4xl my-10">
				<Technique id={id} isEditing />
			</div>
		</>
	);
}

function DeleteTechniqueButton({
	techniqueId,
}: {
	techniqueId: string;
}) {
	const { mutate: deleteTechnique } = useDeleteTechnique();
	const [openDialog, setOpenDialog] = useState(false);
	const navigate = useNavigate();

	return (
		<Dialog open={openDialog} onOpenChange={setOpenDialog}>
			<DialogTrigger asChild>
				<Button id="delete-technique" variant="ghost">
					<Trash2 className="w-4 h-4" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Delete Technique</DialogTitle>
				</DialogHeader>
				<DialogDescription>
					Are you sure you want to delete this technique?
				</DialogDescription>
				<DialogFooter>
					<Button
						id="confirm-delete-technique"
						variant="destructive"
						onClick={() =>
							deleteTechnique(techniqueId, {
								onSuccess: () => {
									setOpenDialog(false);
									navigate({ to: "/techniques" });
								},
							})
						}
					>
						Delete
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
