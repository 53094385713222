import { getConfig, putConfig } from "@/async/db";
import { Config } from "@/hooks/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useGetConfig() {
	return useQuery({
		queryKey: ["config"],
		queryFn: async () => {
			const config = await getConfig();
			if (!config) {
				return {
					theme: { mode: "dark", color: "zinc" },
				} as Config;
			}
			return config;
		},
	});
}

export function usePutConfig() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: putConfig,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["config"] });
		},
	});
}
