import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import React from "react";

interface SubscribedProps {
	children: React.ReactElement<HTMLButtonElement | HTMLLinkElement>;
	text: string;
	disabled: boolean;
}

const Subscribed: React.FC<SubscribedProps> = ({
	children,
	text,
	disabled,
	...props
}) => {
	if (disabled) {
		return (
			<Tooltip>
				<TooltipTrigger asChild>
					<div>
						{React.cloneElement(children, { disabled: true, ...props })}
					</div>
				</TooltipTrigger>
				<TooltipContent>
					<span>{text}</span>
				</TooltipContent>
			</Tooltip>
		);
	}
	return <>{React.cloneElement(children, { disabled: false, ...props })}</>;
};

export default Subscribed;
