import {
	acquireLock,
	getDirtyResources,
	getLastUpdatedAt,
	putResources,
	releaseLock,
	setResourceNotDirty,
	setResourceShared,
} from "@/async/db";
import { Subscription } from "@/hooks/types";

// we use a lock mechanism to prevent multiple syncs from happening at the same time
// particularly between the window thread and the worker thread
export async function syncResources(userId: string, token: string | null) {
	let lock: string | null = null;
	try {
		lock = await acquireLock("syncResources");
		if (!lock) {
			return;
		}
		const dirtyResources = await getDirtyResources(userId);
		// we still sync if there are no dirty resources, to pull any new resources from the server
		if (dirtyResources.length === 0) {
			console.log("syncing without dirty resources");
			const lastUpdatedAt = await getLastUpdatedAt(userId);
			const { resources, updatedAt } = await getResources(
				lastUpdatedAt,
				null,
				token,
			);
			await putResources(userId, updatedAt, resources);
		} else {
			for (const dirtyResource of dirtyResources) {
				console.log("syncing dirty resource", dirtyResource);
				if (
					dirtyResource.kind === "media" &&
					dirtyResource.data.kind === "blob"
				) {
					await base64Encode(dirtyResource);
				}
				const lastUpdatedAt = await getLastUpdatedAt(userId);
				const { resources, updatedAt } = await getResources(
					lastUpdatedAt,
					dirtyResource,
					token,
				);
				await setResourceNotDirty(dirtyResource.id);
				await putResources(userId, updatedAt, resources);
			}
		}
	} finally {
		await releaseLock(lock);
	}
}

// biome-ignore lint/suspicious/noExplicitAny: TODO
async function base64Encode(resource: any): Promise<any> {
	resource.data.source = await new Promise<string>((resolve) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (!reader.result || typeof reader.result !== "string") {
				throw new Error("failed to read blob");
			}
			const base64String = reader.result.replace(/^data:.+;base64,/, "");
			resolve(base64String);
		};
		reader.readAsDataURL(resource.data.source);
	});
}

type APIResource = {
	id: string;
	updatedAt: string | null;
	userId: string | null;
	dirty: boolean;
	deleted: boolean;
	kind: "system" | "technique" | "media";
	shared: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: TODO
	data: any;
};

async function getResources(
	updatedAt: string | null,
	resource: APIResource | null,
	token: string | null,
): Promise<{ updatedAt: string; resources: APIResource[] }> {
	const response = await api(
		"/resources/sync",
		{
			method: "POST",
			body: JSON.stringify({
				updatedAt,
				resource,
			}),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to sync resources");
	}
	const body = await response.json();
	return body;
}

export async function shareResource({
	id,
	shared,
	token,
}: {
	id: string;
	shared: boolean;
	token: string | null;
}): Promise<void> {
	const response = await api(
		"/resources/share",
		{
			method: "POST",
			body: JSON.stringify({ id, shared }),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to share resource");
	}
	await setResourceShared(id, shared);
}

export async function viewSharedResource(
	id: string,
	userId: string,
	token: string | null,
): Promise<{
	resources: { [key: string]: APIResource };
}> {
	const response = await api(
		"/resources/view",
		{
			method: "POST",
			body: JSON.stringify({
				id,
				userId,
			}),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to view shared resource");
	}
	return response.json();
}

export async function getYoutubeInfo(url: string, token: string | null) {
	const response = await api(
		"/youtube/info",
		{
			method: "POST",
			body: JSON.stringify({ url }),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to get youtube info");
	}
	const body = await response.json();

	return body as {
		id: string;
		channel: string;
		duration: number;
		title: string;
		aspectRatio: number;
	};
}

export async function getYoutubeThumbnail(url: string, token: string | null) {
	const response = await api(
		"/youtube/thumbnail",
		{
			method: "POST",
			body: JSON.stringify({ url }),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to get youtube thumbnail");
	}
	return response.blob();
}

export async function getYoutubeClip(
	url: string,
	start: number,
	duration: number,
	token: string | null,
) {
	const response = await api(
		"/youtube/clip",
		{
			method: "POST",
			body: JSON.stringify({
				url,
				start,
				duration,
			}),
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to get youtube clip");
	}
	return response.blob();
}

export async function getCheckoutSession(token: string | null) {
	const response = await api(
		"/subscriptions/checkout_session",
		{
			method: "POST",
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to create checkout session");
	}
	const body = await response.json();
	return body.url as string;
}

export async function getSubscriptionInfo(token: string | null) {
	const response = await api("/subscriptions/info", { method: "POST" }, token);
	if (response.status === 404) {
		return null;
	}
	if (!response.ok) {
		throw new Error("Failed to get subscription info");
	}
	const body = await response.json();
	return body as Subscription;
}

export async function getBillingPortalSession(token: string | null) {
	const response = await api(
		"/subscriptions/billing_portal_session",
		{
			method: "POST",
		},
		token,
	);
	if (!response.ok) {
		throw new Error("Failed to create checkout session");
	}
	const body = await response.json();
	return body.url as string;
}

export async function getUser(token: string | null) {
	const response = await api("/users/me", { method: "POST" }, token);
	if (response.status === 404) {
		return null;
	}
	if (!response.ok) {
		throw new Error("Failed to check subscription status");
	}
	const body = await response.json();
	return body as {
		id: string;
		customer_id: string;
		active: boolean | null;
		ttl: number | null;
	};
}

async function api(
	resource: string,
	opts: RequestInit,
	token: string | null,
): Promise<Response> {
	const url = `${import.meta.env.VITE_DOJU_API_BASE_URL}/v1${resource}`;

	if (!token) {
		throw new Error("No token found");
	}

	return fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		...opts,
	});
}
