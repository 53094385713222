import { ReactNode } from "react";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key");
}

export function AuthProvider({ children }: { children: ReactNode }) {
	return (
		<ClerkProvider publishableKey={PUBLISHABLE_KEY}>
			<WaitForUserInfo>{children}</WaitForUserInfo>
		</ClerkProvider>
	);
}

// We don't want hooks to run with incorrect user info and then cache results
// We should wait for the user info to be loaded before rendering anything that depends on it (pretty much everything depends on it)
export function WaitForUserInfo({ children }: { children: ReactNode }) {
	const { isLoaded } = useAuth();
	if (!isLoaded) {
		return null;
	}
	return <>{children}</>;
}
