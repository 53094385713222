import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { Media } from "@/routes/techniques/components/media";
import { Technique } from "@/hooks/types";
import {
	useNewTechnique,
	useGetTechniques,
	useGetTechniqueThumbnail,
} from "@/hooks/use-techniques";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { Pencil, PlusCircle } from "lucide-react";
import { useUser } from "@/hooks/use-user";
import Subscribed from "@/components/ui/subscribed";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Center } from "@/components/ui/center";
import { Spinner } from "@/components/ui/spinner";

export const Route = createFileRoute("/techniques/")({
	component: Techniques,
});

function Techniques() {
	const { data: user } = useUser();
	const navigate = useNavigate();
	const { mutate: newTechnique } = useNewTechnique();
	const {
		data: techniques,
		isPending,
		isError,
	} = useGetTechniques({ associatedSystem: null });

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the techniques at this time."
				/>
			</Center>
		);
	}

	if (techniques.length === 0) {
		return (
			<Center>
				<Card>
					<CardHeader>
						<CardTitle className="text-center mb-2">
							Create a technique
						</CardTitle>
						<CardDescription className="text-center">
							Techniques should represent a single position or movement
						</CardDescription>
					</CardHeader>
					<CardContent>
						<Button
							id="create-first-technique"
							className="w-full"
							onClick={() =>
								newTechnique(
									{ associatedSystem: null },
									{
										onSuccess: (id) =>
											navigate({ to: "/techniques/$id/edit", params: { id } }),
									},
								)
							}
						>
							New Technique
						</Button>
					</CardContent>
				</Card>
			</Center>
		);
	}

	return (
		<div className="container max-w-6xl my-10">
			<div className="flex flex-row-reverse">
				<Subscribed
					disabled={techniques.length >= 10 && !user?.active}
					text="Subscribe to be able to create more techniques"
				>
					<Button
						id="new-technique"
						onClick={() =>
							newTechnique(
								{ associatedSystem: null },
								{
									onSuccess: (id) =>
										navigate({ to: "/techniques/$id/edit", params: { id } }),
								},
							)
						}
					>
						<PlusCircle className="w-5 h-5 mr-2" /> New Technique
					</Button>
				</Subscribed>
			</div>
			<div className=" mt-10 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
				{techniques.map((technique) => (
					<TechniqueCard key={technique.id} technique={technique} />
				))}
			</div>
		</div>
	);
}

function TechniqueCard({
	technique: {
		id,
		data: { name },
	},
}: { technique: Technique }) {
	const { data: thumbnail } = useGetTechniqueThumbnail(id);

	return (
		<div className="rounded-lg border bg-card text-card-foreground shadow-sm">
			<div className="flex">
				<Link key={id} to="/techniques/$id" params={{ id }} className="grow">
					<h3 className="text-2xl font-semibold leading-none tracking-tight pl-16 pt-4 text-center">
						{name}
					</h3>
				</Link>
				<Button
					id="edit-technique"
					className="p-5 py-7"
					variant="ghost"
					type="button"
					asChild
				>
					<Link to="/techniques/$id/edit" params={{ id }}>
						<Pencil className="w-4 h-4" />
					</Link>
				</Button>
			</div>
			<Link key={id} to="/techniques/$id" params={{ id }}>
				<div className="flex min-h-[172px] p-4 items-center justify-center">
					{thumbnail && (
						<Media
							className="h-auto max-h-[172px] rounded-lg"
							type={thumbnail.data.type}
							data={thumbnail.data.source}
							kind={thumbnail.data.kind}
							alt="thumbnail"
							autoPlay
							playsInline
							loop
							muted
						/>
					)}
				</div>
			</Link>
		</div>
	);
}
