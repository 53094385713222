import {
	getResource,
	newResource,
	putResource,
	deleteResource,
} from "@/async/db";
import { Media, MediaData, Technique } from "@/hooks/types";
import { useSyncResources } from "@/hooks/use-sync";
import { useWorker } from "@/hooks/use-worker";
import { MessageKind } from "@/worker/worker";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";

export function useGetMedia(id: string) {
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["media", id],
		queryFn: async (): Promise<Media | null> => {
			return getResource(id);
		},
		enabled: synced,
	});
}

export function useNewMedia(techniqueId: string) {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (mediaData: MediaData) => {
			const technique = await getResource(techniqueId);
			if (!technique) {
				throw new Error(`Technique with id ${techniqueId} not found`);
			}
			const mediaId = nanoid();
			technique.data.media.push({
				id: mediaId,
			});
			await newResource("media", mediaId, mediaData);
			await putResource(techniqueId, technique.data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["techniques", techniqueId],
			});
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useDeleteMedia(techniqueId: string) {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (mediaId: string) => {
			const technique: Technique | null = await getResource(techniqueId);
			if (!technique) {
				throw new Error(`Technique with id ${techniqueId} not found`);
			}
			technique.data.media = technique.data.media.filter(
				(m) => m.id !== mediaId,
			);
			await putResource(techniqueId, technique.data);
			await deleteResource(mediaId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["techniques", techniqueId],
			});
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useMoveMedia(techniqueId: string) {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async ({
			mediaId,
			newIndex,
		}: { mediaId: string; newIndex: number }) => {
			const technique: Technique | null = await getResource(techniqueId);
			if (!technique) {
				throw new Error(`Technique with id ${techniqueId} not found`);
			}
			const oldIndex = technique.data.media.findIndex((m) => m.id === mediaId);
			const media = technique.data.media.splice(oldIndex, 1);
			technique.data.media.splice(newIndex, 0, media[0]);
			await putResource(techniqueId, technique.data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["techniques", techniqueId],
			});
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}
