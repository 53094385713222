import { Message, MessageKind, createWorker } from "@/worker/worker";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery } from "@tanstack/react-query";

/**
 * Maintain a singleton instance of the worker that can be fetched from anywhere
 * This wrapper can be updated to expose more of the Worker API as needed
 * TODO: Is tanstack query the best way to maintain this singleton? Some other state management library?
 */
export function useWorker() {
	return useQuery({
		queryKey: ["worker"],
		queryFn: () =>
			createWorker({
				onMessage: (message: Message) => {
					switch (message.kind) {
						case MessageKind.ResourcesChanged:
							break;
						default:
							console.error(
								"unspoorted message type on main thread: ",
								message.kind,
							);
					}
				},
			}),
		staleTime: Infinity,
		gcTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
}

export function usePostUserContext() {
	const { data: worker } = useWorker();
	const { getToken, userId } = useAuth();
	return useMutation({
		mutationFn: async () => {
			const token = await getToken();
			if (!token || !userId) {
				worker?.postMessage({
					kind: MessageKind.UserContext,
					data: null,
				});
			} else {
				worker?.postMessage({
					kind: MessageKind.UserContext,
					data: { token, userId },
				});
			}
		},
	});
}
