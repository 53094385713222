import { shareResource, viewSharedResource } from "@/async/api";
import {
	deleteResource,
	getResource,
	getResources,
	newResource,
	putResource,
	putSharedResource,
} from "@/async/db";
import { System, SystemData } from "@/hooks/types";
import { useSyncResources } from "@/hooks/use-sync";
import { useWorker } from "@/hooks/use-worker";
import { MessageKind } from "@/worker/worker";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";

export function useGetSystems() {
	const { userId } = useAuth();
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["systems"],
		queryFn: async (): Promise<System[]> => {
			const systems = await getResources("system");
			return systems
				.filter(
					(system) =>
						(system.userId === userId || system.userId === null) &&
						!system.deleted,
				)
				.sort((s1, s2) => s2.data.createdAt - s1.data.createdAt);
		},
		enabled: synced,
	});
}

export function useGetSystem(id: string) {
	const { data: synced } = useSyncResources();
	return useQuery({
		queryKey: ["systems", id],
		queryFn: async (): Promise<System | null> => getResource(id),
		enabled: synced,
	});
}

export function useNewSystem() {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async () =>
			newResource("system", nanoid(), {
				name: "Unnamed System",
				nodes: [],
				edges: [],
				createdAt: Date.now(),
			}),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["systems"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function usePutSystem(id: string) {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (data: SystemData) => putResource(id, data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["systems"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useDeleteSystem() {
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async (id: string) => deleteResource(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["systems"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useShareSystem() {
	const { getToken } = useAuth();
	const queryClient = useQueryClient();
	const { data: worker } = useWorker();
	return useMutation({
		mutationFn: async ({ id, shared }: { id: string; shared: boolean }) => {
			const token = await getToken();
			return shareResource({ id, shared, token });
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["systems"] });
			worker?.postMessage({
				kind: MessageKind.ResourcesChanged,
			});
		},
	});
}

export function useViewSharedSystem({
	id,
	userId,
}: { id: string; userId: string }) {
	const { getToken } = useAuth();
	return useQuery({
		queryKey: ["systems", userId, id, "view"],
		queryFn: async (): Promise<System | null> => {
			const token = await getToken();
			const { resources } = await viewSharedResource(id, userId, token);
			for (const resource of Object.values(resources)) {
				await putSharedResource(resource);
			}
			return getResource(id);
		},
	});
}
