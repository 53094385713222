import {
	getBillingPortalSession,
	getCheckoutSession,
	getSubscriptionInfo,
} from "@/async/api";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery } from "@tanstack/react-query";

export function useCheckout() {
	const { getToken } = useAuth();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async () => {
			const token = await getToken();
			return getCheckoutSession(token);
		},
		onSuccess: (url) => {
			window.location.href = url;
		},
		onError: (error) => {
			toast({
				title: "Error creating checkout session",
				description: `${error}`,
				variant: "destructive",
			});
		},
	});
}

export function useSubscriptionInfo() {
	const { getToken, userId } = useAuth();
	return useQuery({
		queryKey: ["subscriptions", userId, "info"],
		queryFn: async () => {
			const token = await getToken();
			return getSubscriptionInfo(token);
		},
	});
}

export function useBillingPortal() {
	const { getToken } = useAuth();
	const { toast } = useToast();
	return useMutation({
		mutationFn: async () => {
			const token = await getToken();
			return getBillingPortalSession(token);
		},
		onSuccess: (url) => {
			window.location.href = url;
		},
		onError: (error) => {
			toast({
				title: "Error creating billing portal session",
				description: `${error}`,
				variant: "destructive",
			});
		},
	});
}
