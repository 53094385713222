import { Button } from "@/components/ui/button";
import { useViewSharedSystem } from "@/hooks/use-systems";
import { TechniqueSheet } from "@/routes/systems/components/technique-sheet";
import { TechniqueNode } from "@/routes/systems/components/technique-node";
import { Link, Navigate } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { useMemo } from "react";
import ReactFlow, { ConnectionMode, EdgeProps, NodeProps } from "reactflow";
import { z } from "zod";
import { TechniquesEdge } from "@/routes/systems/components/technique-edge";
import { useAuth } from "@clerk/clerk-react";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Center } from "@/components/ui/center";
import { Spinner } from "@/components/ui/spinner";

const shareSystemSchema = z.object({
	techniqueId: z.string().optional(),
});

export const Route = createFileRoute("/systems/share/$data")({
	component: () => null,
	validateSearch: shareSystemSchema,
});

// TODO: Disabling system sharing for now
export function ShareSystem() {
	const { data } = Route.useParams();
	const { techniqueId } = Route.useSearch();
	const [userId, id] = useMemo(() => atob(data).split(":"), [data]);
	const {
		data: system,
		isPending,
		isError,
	} = useViewSharedSystem({ id, userId });
	const { userId: loggedInUserId } = useAuth();

	if (!loggedInUserId) {
		return <Navigate to="/signin" />;
	}

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the system at this time."
				/>
			</Center>
		);
	}

	if (!system) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not find the system you are looking for."
				/>
			</Center>
		);
	}

	return (
		<div>
			<div className="flex h-14 w-full border-b items-center">
				<Button id="share-system-back" variant="ghost" asChild>
					<Link to="/systems" params={{ id }}>
						<ChevronLeft className="w-4 h-4" />
					</Link>
				</Button>
				<h1 className="text-lg grow ml-4">{system.data.name}</h1>
			</div>
			<div className="h-[calc(100vh-112px)] sm:h-[calc(100vh-56px)] w-full">
				<ReactFlow
					id="system-view"
					nodes={system.data.nodes}
					edges={system.data.edges}
					fitView
					proOptions={{
						hideAttribution: true,
					}}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					connectionMode={ConnectionMode.Loose}
					snapToGrid
				/>
				<TechniqueSheet techniqueId={techniqueId} isEditing={false} />
			</div>
		</div>
	);
}

const nodeTypes = {
	technique: (props: NodeProps<NodeData>) => (
		<TechniqueNode mode="view" props={props} />
	),
};

const edgeTypes = {
	techniquesEdge: (props: EdgeProps<EdgeData>) => (
		<TechniquesEdge mode="view" props={props} />
	),
};
