import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export function Markdown({ children }: { children: string }) {
	return (
		<ReactMarkdown
			remarkPlugins={[remarkGfm]}
			components={{
				h1: ({ children }) => (
					<h1 className="text-xl font-semibold">{children}</h1>
				),
				h2: ({ children }) => (
					<h2 className="text-lg font-semibold">{children}</h2>
				),
				h3: ({ children }) => (
					<h3 className="text-lg font-semibold">{children}</h3>
				),
				h4: ({ children }) => (
					<h4 className="text-lg font-semibold">{children}</h4>
				),
				p: ({ children }) => <p className="text-base">{children}</p>,
				a: ({ children, href }) => (
					<a
						href={href as string}
						target="_blank"
						rel="noreferrer"
						className="text-blue-600 hover:underline"
					>
						{children}
					</a>
				),
				br: () => <br />,
				ul: ({ children }) => <ul className="list-disc ml-6">{children}</ul>,
				ol: ({ children }) => <ol className="list-decimal ml-6">{children}</ol>,
				li: ({ children }) => <li className="text-base">{children}</li>,
				blockquote: ({ children }) => (
					<blockquote className="italic">{children}</blockquote>
				),
				code: ({ children }) => (
					<code className="text-sm bg-code text-code">{children}</code>
				),

				table: ({ children }) => (
					<Table className="text-primary">{children}</Table>
				),
				thead: ({ children }) => (
					<TableHeader className="text-primary">{children}</TableHeader>
				),
				tbody: ({ children }) => (
					<TableBody className="text-primary">{children}</TableBody>
				),
				tr: ({ children }) => (
					<TableRow className="text-primary">{children}</TableRow>
				),
				th: ({ children }) => (
					<TableHead className="text-primary">{children}</TableHead>
				),
				td: ({ children }) => (
					<TableCell className="text-primary">{children}</TableCell>
				),
			}}
		>
			{children}
		</ReactMarkdown>
	);
}
