import { Button } from "@/components/ui/button";
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "@/components/ui/input-otp";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Spinner } from "@/components/ui/spinner";
import { useVerifyEmail } from "@/hooks/use-auth";

export const Route = createFileRoute("/_auth/verify")({
	component: Verify,
});

function Verify() {
	const [code, setCode] = useState("");
	const { mutate, isPending } = useVerifyEmail();

	return (
		<div className="w-full max-w-sm">
			<div className="text-2xl text-center text-bold">Verify Email</div>
			<div className="flex items-center justify-center mt-4">
				<div className="text-sm text-center text-muted-foreground w-60">
					Enter the code sent to your email address
				</div>
			</div>
			<form
				className="flex items-center justify-center mt-2 "
				onSubmit={(e) => {
					e.preventDefault();
					mutate({ code });
				}}
			>
				<div className="w-60 flex-col">
					<InputOTP
						maxLength={6}
						value={code}
						onChange={(value) => setCode(value)}
						disabled={isPending}
					>
						<InputOTPGroup className="bg-background">
							<InputOTPSlot index={0} />
							<InputOTPSlot index={1} />
							<InputOTPSlot index={2} />
							<InputOTPSlot index={3} />
							<InputOTPSlot index={4} />
							<InputOTPSlot index={5} />
						</InputOTPGroup>
					</InputOTP>
					<Button
						id="verify-email"
						type="submit"
						className="w-60 mt-6"
						disabled={isPending}
					>
						{isPending && <Spinner className="h-4 w-4 mr-2" />}
						Verify
					</Button>
				</div>
			</form>
		</div>
	);
}
