// From: https://shadcnui-expansions.typeart.cc/docs/autosize-textarea
// Keep an eye on browser support for field-sizing prop: https://developer.mozilla.org/en-US/docs/Web/CSS/field-sizing
// Maybe use: https://www.npmjs.com/package/react-textarea-autosize
import * as React from "react";
import { cn } from "@/components/lib/utils";
import { useImperativeHandle } from "react";

interface UseAutosizeTextAreaProps {
	textAreaRef: HTMLTextAreaElement | null;
	minHeight?: number;
	maxHeight?: number;
	triggerAutoSize: string;
}

export const useAutosizeTextArea = ({
	textAreaRef,
	triggerAutoSize,
	maxHeight = Number.MAX_SAFE_INTEGER,
	minHeight = 0,
}: UseAutosizeTextAreaProps) => {
	const [init, setInit] = React.useState(true);
	// biome-ignore lint/correctness/useExhaustiveDependencies: external code
	React.useEffect(() => {
		// We need to reset the height momentarily to get the correct scrollHeight for the textarea
		const offsetBorder = 2;
		if (textAreaRef) {
			if (init) {
				textAreaRef.style.minHeight = `${minHeight + offsetBorder}px`;
				if (maxHeight > minHeight) {
					textAreaRef.style.maxHeight = `${maxHeight}px`;
				}
				setInit(false);
			}
			textAreaRef.style.height = `${minHeight + offsetBorder}px`;
			const scrollHeight = textAreaRef.scrollHeight;
			// We then set the height directly, outside of the render loop
			// Trying to set this with state or a ref will product an incorrect value.
			if (scrollHeight > maxHeight) {
				textAreaRef.style.height = `${maxHeight}px`;
			} else {
				textAreaRef.style.height = `${scrollHeight + offsetBorder}px`;
			}
		}
	}, [textAreaRef, triggerAutoSize]);
};

export type AutosizeTextAreaRef = {
	textArea: HTMLTextAreaElement;
	maxHeight: number;
	minHeight: number;
};

type AutosizeTextAreaProps = {
	maxHeight?: number;
	minHeight?: number;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const AutosizeTextarea = React.forwardRef<
	AutosizeTextAreaRef,
	AutosizeTextAreaProps
>(
	(
		{
			maxHeight = Number.MAX_SAFE_INTEGER,
			minHeight = 52,
			className,
			onChange,
			value,
			...props
		}: AutosizeTextAreaProps,
		ref: React.Ref<AutosizeTextAreaRef>,
	) => {
		const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
		const [triggerAutoSize, setTriggerAutoSize] = React.useState("");

		useAutosizeTextArea({
			textAreaRef: textAreaRef.current,
			triggerAutoSize: triggerAutoSize,
			maxHeight,
			minHeight,
		});

		useImperativeHandle(ref, () => ({
			textArea: textAreaRef.current as HTMLTextAreaElement,
			focus: () => textAreaRef.current?.focus(),
			maxHeight,
			minHeight,
		}));

		// biome-ignore lint/correctness/useExhaustiveDependencies: external code
		React.useEffect(() => {
			if (value || props?.defaultValue) {
				setTriggerAutoSize(value as string);
			}
		}, [value || props?.defaultValue]);

		return (
			<textarea
				{...props}
				value={value}
				ref={textAreaRef}
				className={cn(
					"flex w-full border border-input bg-background px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
					"text-base", // to avoid zoom on focus in iOS
					className,
				)}
				onChange={(e) => {
					setTriggerAutoSize(e.target.value);
					onChange?.(e);
				}}
			/>
		);
	},
);
AutosizeTextarea.displayName = "AutosizeTextarea";
