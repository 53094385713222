import { Button } from "@/components/ui/button";
import { useGetTechnique } from "@/hooks/use-techniques";
import { Link, createFileRoute } from "@tanstack/react-router";
import { ChevronLeft, Pencil } from "lucide-react";
import { Technique } from "@/routes/techniques/components/technique";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Center } from "@/components/ui/center";
import { Spinner } from "@/components/ui/spinner";

export const Route = createFileRoute("/techniques/$id")({
	component: ViewTechnique,
});

function ViewTechnique() {
	const { id } = Route.useParams();
	const { isPending, isError } = useGetTechnique(id);

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not find the technique you are looking for."
				/>
			</Center>
		);
	}

	return (
		<>
			<div className="flex mx-3 sm:mx-5 mt-3 justify-center items-center">
				<Button id="view-technique-back" variant="ghost" asChild>
					<Link to="/techniques" params={{ id }}>
						<ChevronLeft className="w-4 h-4" />
					</Link>
				</Button>
				<div className="grow" />
				<Button
					id="view-technique-edit"
					className=""
					variant="ghost"
					type="button"
					asChild
				>
					<Link to="/techniques/$id/edit" params={{ id }}>
						<Pencil className="w-4 h-4" />
					</Link>
				</Button>
			</div>
			<div className="container max-w-4xl my-10">
				<Technique id={id} />
			</div>
		</>
	);
}
