import { useEffect, useState } from "react";

export function Media({
	className,
	type,
	kind,
	data,
	alt,
	autoPlay,
	playsInline,
	muted,
	loop,
	controls,
}: {
	className?: string;
	type: "image" | "video";
	kind: "url" | "file" | "blob";
	data: string | Blob | File;
	alt: string;
	autoPlay?: boolean;
	playsInline?: boolean;
	muted?: boolean;
	loop?: boolean;
	controls?: boolean;
}) {
	const [src, setSrc] = useState<string | null>(null);

	useEffect(() => {
		if (kind === "blob") {
			/**
			 * Certain devices don't seem to play nice with URL.createObjectURL(blob) for videos.
			 * This is the result of a bunch of trial and error to get videos to play on my iphone 11.
			 */
			const reader = new FileReader();
			reader.onload = () => {
				if (!reader.result || typeof reader.result !== "string") {
					return;
				}
				setSrc(reader.result);
			};
			reader.readAsDataURL(data as Blob);
		} else if (kind === "file") {
			setSrc(URL.createObjectURL(data as File));
		} else {
			setSrc(data as string);
		}
	}, [data, kind]);

	if (!src) {
		return null;
	}

	if (type === "image") {
		return <img src={src} className={className} alt={alt} />;
	}

	return (
		<video
			src={src}
			className={className}
			autoPlay={autoPlay}
			playsInline={playsInline}
			muted={muted}
			loop={loop}
			controls={controls}
		/>
	);
}
