import "reactflow/dist/style.css";
import "./system.css";

import { useGetSystem } from "@/hooks/use-systems";
import { createFileRoute } from "@tanstack/react-router";
import ReactFlow, { ConnectionMode, EdgeProps, NodeProps } from "reactflow";
import { TechniqueNode } from "@/routes/systems/components/technique-node";
import z from "zod";
import { Button } from "@/components/ui/button";
import { ChevronLeft, Pencil } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { TechniqueSheet } from "@/routes/systems/components/technique-sheet";
import { TechniquesEdge } from "@/routes/systems/components/technique-edge";
import { ErrorAlert } from "@/components/ui/error-alert";
import { Center } from "@/components/ui/center";
import { Spinner } from "@/components/ui/spinner";

const systemViewSchema = z.object({
	techniqueId: z.string().optional(),
});

export const Route = createFileRoute("/systems/$id")({
	component: ViewSystem,
	validateSearch: systemViewSchema,
});

function ViewSystem() {
	const { id } = Route.useParams();
	const { techniqueId } = Route.useSearch();
	const { data: system, isPending, isError } = useGetSystem(id);

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the system at this time."
				/>
			</Center>
		);
	}

	if (!system) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not find the system you are looking for."
				/>
			</Center>
		);
	}

	return (
		<div>
			<div className="flex h-14 w-full border-b items-center">
				<Button id="view-system-back" variant="ghost" asChild>
					<Link to="/systems" params={{ id }}>
						<ChevronLeft className="w-4 h-4" />
					</Link>
				</Button>
				<h1 className="text-lg grow ml-4">{system.data.name}</h1>
				{/* <ShareSystemButton id={system.id} shared={system.shared} /> */}
				<Button id="view-system-edit" variant="ghost" className="mr-2" asChild>
					<Link to="/systems/$id/edit" params={{ id }}>
						<Pencil className="w-4 h-4" />
					</Link>
				</Button>
			</div>
			<div className="h-[calc(100vh-112px)] sm:h-[calc(100vh-56px)] w-full">
				<ReactFlow
					id="system-view"
					nodes={system.data.nodes}
					edges={system.data.edges}
					fitView
					proOptions={{
						hideAttribution: true,
					}}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					connectionMode={ConnectionMode.Loose}
					snapToGrid
				/>
				<TechniqueSheet techniqueId={techniqueId} isEditing={false} />
			</div>
		</div>
	);
}

const nodeTypes = {
	technique: (props: NodeProps<NodeData>) => (
		<TechniqueNode mode="view" props={props} />
	),
};

const edgeTypes = {
	techniquesEdge: (props: EdgeProps<EdgeData>) => (
		<TechniquesEdge mode="view" props={props} />
	),
};
