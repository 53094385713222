import { Button } from "@/components/ui/button";
import { useYoutubeDownload, useYoutubeMetadata } from "@/hooks/use-youtube";
import { useState } from "react";
import { InfoButton } from "@/components/ui/info-button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/spinner";
import { Check, Circle, Scissors } from "lucide-react";
import { useNewMedia } from "@/hooks/use-media";
import { parseSeconds } from "@/routes/techniques/components/format";
import { Media } from "@/routes/techniques/components/media";
import { YoutubeEmbedded } from "@/components/ui/youtube-embedded";
import { useToast } from "@/components/ui/use-toast";
import { TimestampInput } from "@/components/ui/timestamp-input";

type Metadata = {
	id: string;
	url: string;
	title: string;
	channel: string;
	duration: number;
	aspectRatio: number;
};

type Clip = {
	blob: Blob;
	start: number;
	origin: string;
	aspectRatio: number;
};

// TODO: Add Restart button
export function YoutubeForm({
	techniqueId,
	onSuccess,
}: { techniqueId: string; onSuccess: () => void }) {
	const [metadata, setMetadata] = useState<Metadata | null>(null);
	const [clip, setClip] = useState<Clip | null>(null);

	return (
		<div className="flex flex-col space-y-5">
			<div className="flex space-x-3 items-center">
				{metadata ? (
					<Check className="w-4 h-4" />
				) : (
					<Circle className="w-4 h-4" />
				)}
				<Label className="text-md">Provide video url</Label>
				<InfoButton id="provide-yt-url-info">
					Provide a URL to a Youtube video to extract a clip from it.
				</InfoButton>
			</div>
			{!metadata ? (
				<ProvideURLStep setMetadata={setMetadata} />
			) : (
				<>
					<div className="flex space-x-3 items-center">
						{clip ? (
							<Check className="w-4 h-4" />
						) : (
							<Circle className="w-4 h-4" />
						)}
						<Label className="text-md">Generate clip</Label>
						<InfoButton id="generate-yt-clip-info">
							Clip duration must be a maximum of 60 seconds. Clipping videos can
							take a while depending on the duration of the clip and the specs
							of your machine.
						</InfoButton>
					</div>
					{!clip ? (
						<GenerateClipStep metadata={metadata} setClip={setClip} />
					) : (
						<>
							<div className="flex space-x-3 items-center">
								<Circle className="w-4 h-4" />
								<Label className="text-md">Confirm details</Label>
							</div>
							<ConfirmStep
								techniqueId={techniqueId}
								clip={clip}
								onSuccess={onSuccess}
							/>
						</>
					)}
				</>
			)}
		</div>
	);
}

function ProvideURLStep({
	setMetadata,
}: { setMetadata: (metadata: Metadata) => void }) {
	const [url, setURL] = useState("");
	const { mutate, isPending } = useYoutubeMetadata({
		onSuccess: (metadata) => {
			setMetadata(metadata);
		},
	});
	return (
		<>
			<Input
				id="source"
				type="url"
				onChange={(event) => {
					setURL(event.target.value);
				}}
			/>
			<Button
				id="provide-yt-url"
				disabled={isPending || !url}
				onClick={() => {
					mutate({ url });
				}}
			>
				{isPending && <Spinner className="mr-2 h-4 w-4" />}
				Confirm
			</Button>
		</>
	);
}

function GenerateClipStep({
	metadata,
	setClip,
}: { metadata: Metadata; setClip: (clip: Clip) => void }) {
	const [start, setStart] = useState("");
	const [end, setEnd] = useState("");
	const { toast } = useToast();
	const { mutate, isPending } = useYoutubeDownload({
		onSuccess: (blob) => {
			setClip({
				blob,
				start: parseSeconds(start) ?? 0,
				origin: metadata.url,
				aspectRatio: metadata.aspectRatio,
			});
		},
	});

	return (
		<div className="flex flex-col">
			<div className="mb-3">
				<YoutubeEmbedded className="w-full h-60" embedId={metadata.id} />
			</div>
			<div className="mb-6">
				<Label className="text-md">Start time HH:MM:SS</Label>
				<TimestampInput
					disabled={isPending}
					value={start}
					onChange={setStart}
				/>
			</div>
			<div className="mb-6">
				<Label className="text-md">End time HH:MM:SS</Label>
				<TimestampInput disabled={isPending} value={end} onChange={setEnd} />
			</div>
			{
				// TODO: Add warning about how long this can take
			}
			<Button
				id="clip-yt-video"
				className="my-3"
				disabled={isPending}
				type="button"
				onClick={() => {
					const startSeconds = parseSeconds(start);
					const endSeconds = parseSeconds(end);
					if (startSeconds === undefined || endSeconds === undefined) {
						toast({
							variant: "destructive",
							title: "Invalid timestamp",
							description: "Please enter valid timestamps",
						});
						return;
					}
					if (endSeconds - startSeconds > 60) {
						toast({
							variant: "destructive",
							title: "Clip too long",
							description: "Clips must be a maximum of 60 seconds",
						});
						return;
					}
					if (endSeconds <= startSeconds) {
						toast({
							variant: "destructive",
							title: "Invalid clip",
							description: "End time must be after start time",
						});
						return;
					}
					if (endSeconds > metadata.duration) {
						toast({
							variant: "destructive",
							title: "Invalid clip",
							description: "End time cannot exceed video length",
						});
						return;
					}
					mutate({
						source: metadata.url,
						start: startSeconds,
						duration: endSeconds - startSeconds,
					});
				}}
			>
				{isPending ? (
					<Spinner className="mr-2 h-4 w-4" />
				) : (
					<Scissors className="mr-2 w-4 h-4" />
				)}
				Clip
			</Button>
		</div>
	);
}

function ConfirmStep({
	techniqueId,
	clip,
	onSuccess,
}: { techniqueId: string; clip: Clip; onSuccess: () => void }) {
	const { mutate: addMedia } = useNewMedia(techniqueId);
	return (
		<div className="flex flex-col">
			<Media
				type="video"
				data={clip.blob}
				kind="blob"
				alt="preview"
				autoPlay
				playsInline
				muted
				loop
				controls
			/>
			<Button
				id="confirm-yt-clip"
				className="mt-6"
				onClick={() =>
					addMedia(
						{
							kind: "blob",
							source: clip.blob,
							type: "video",
							origin: `${clip.origin}&t=${clip.start}`,
							aspectRatio: clip.aspectRatio,
							ext: "mp4",
						},
						{
							onSuccess,
						},
					)
				}
			>
				<Check className="mr-2 w-5 h-5" />
				Confirm
			</Button>
		</div>
	);
}
