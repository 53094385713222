import "reactflow/dist/style.css";
import "./system.css";

import { Button } from "@/components/ui/button";
import {
	Card,
	CardHeader,
	CardTitle,
	CardContent,
	CardDescription,
} from "@/components/ui/card";
import { useGetSystems, useNewSystem } from "@/hooks/use-systems";
import { Link, createFileRoute, useNavigate } from "@tanstack/react-router";
import { Pencil, PlusCircle } from "lucide-react";
import ReactFlow, {
	ConnectionMode,
	Edge,
	EdgeProps,
	Node,
	NodeProps,
	SmoothStepEdge,
} from "reactflow";
import { System } from "@/hooks/types";
import { TechniqueNode } from "@/routes/systems/components/technique-node";
import { Spinner } from "@/components/ui/spinner";
import { TechniquesEdge } from "@/routes/systems/components/technique-edge";
import Subscribed from "@/components/ui/subscribed";
import { useUser } from "@/hooks/use-user";
import { Center } from "@/components/ui/center";
import { ErrorAlert } from "@/components/ui/error-alert";

export const Route = createFileRoute("/systems/")({
	component: Systems,
});

export function Systems() {
	const { data: systems, isPending, isError } = useGetSystems();
	const navigate = useNavigate();
	const { mutate: newSystem } = useNewSystem();
	const { data: user } = useUser();

	if (isPending) {
		return (
			<Center>
				<Spinner />
			</Center>
		);
	}

	if (isError) {
		return (
			<Center>
				<ErrorAlert
					title="Uh oh!"
					description="We could not load the systems at this time."
				/>
			</Center>
		);
	}

	if (systems.length === 0) {
		return (
			<Center>
				<Card>
					<CardHeader>
						<CardTitle className="text-center mb-2">Create a system</CardTitle>
						<CardDescription className="text-center">
							Systems are composed of a progression of{" "}
							<Link to="/techniques" className="underline text-primary">
								techniques
							</Link>
						</CardDescription>
					</CardHeader>
					<CardContent>
						<Button
							id="create-first-system"
							className="w-full"
							type="button"
							onClick={() =>
								newSystem(undefined, {
									onSuccess: (id) =>
										navigate({ to: "/systems/$id/edit", params: { id } }),
								})
							}
						>
							New System
						</Button>
					</CardContent>
				</Card>
			</Center>
		);
	}

	return (
		<div className="container max-w-6xl my-10">
			<div className="flex flex-row-reverse">
				<Subscribed
					disabled={systems.length >= 3 && !user?.active}
					text="Subscribe to be able to create more systems"
				>
					<Button
						id="new-system"
						type="button"
						onClick={() =>
							newSystem(undefined, {
								onSuccess: (id) =>
									navigate({ to: "/systems/$id/edit", params: { id } }),
							})
						}
					>
						<PlusCircle className="w-5 h-5 mr-2" /> New System
					</Button>
				</Subscribed>
			</div>
			<div className="mt-10 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
				{systems.map((system) => (
					<SystemCard key={system.id} system={system} />
				))}
			</div>
		</div>
	);
}

function SystemCard({
	system: {
		id,
		data: { name, nodes, edges },
	},
}: { system: System }) {
	return (
		<div className="rounded-lg border bg-card text-card-foreground shadow-sm">
			<div className="flex">
				<Link key={id} to="/systems/$id" params={{ id }} className="grow">
					<h3 className="text-2xl font-semibold leading-none tracking-tight pl-16 pt-4 text-center">
						{name}
					</h3>
				</Link>
				<Button
					id="edit-system"
					className="p-5 py-7"
					variant="ghost"
					type="button"
					asChild
				>
					<Link to="/systems/$id/edit" params={{ id }}>
						<Pencil className="w-4 h-4" />
					</Link>
				</Button>
			</div>
			<Link key={id} to="/systems/$id" params={{ id }}>
				<div className="flex h-56 w-full p-4 items-center justify-center">
					<SystemThumbnail nodes={nodes} edges={edges} />
				</div>
			</Link>
		</div>
	);
}

function SystemThumbnail({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) {
	return (
		<ReactFlow
			id="system-thumbnail"
			nodes={nodes}
			edges={edges}
			fitView
			proOptions={{
				hideAttribution: true,
			}}
			edgesUpdatable={false}
			edgesFocusable={false}
			nodesDraggable={false}
			nodesConnectable={false}
			nodesFocusable={false}
			draggable={false}
			panOnDrag={false}
			elementsSelectable={false}
			zoomOnDoubleClick={false}
			zoomOnPinch={false}
			zoomOnScroll={false}
			nodeTypes={nodeTypes}
			edgeTypes={edgeTypes}
			connectionMode={ConnectionMode.Loose}
		/>
	);
}

const nodeTypes = {
	technique: (props: NodeProps<NodeData>) => (
		<TechniqueNode mode="thumbnail" props={props} />
	),
};

const edgeTypes = {
	techniquesEdge: (props: EdgeProps<EdgeData>) => (
		<TechniquesEdge mode="view" props={props} />
	),
	default: SmoothStepEdge,
};
