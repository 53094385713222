import { Link, createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/spinner";
import { useEmailSignUp } from "@/hooks/use-auth";
import { Checkbox } from "@/components/ui/checkbox";
import { Check } from "lucide-react";

export const Route = createFileRoute("/_auth/signup")({
	component: SignUp,
});

function SignUp() {
	const [emailAddress, setEmailAddress] = useState("");
	const [password, setPassword] = useState("");
	const [terms, setTerms] = useState<boolean | "indeterminate">(false);
	const { mutate, isPending } = useEmailSignUp();

	return (
		<div className="grid sm:grid-cols-2 justify-items-center w-full max-w-5xl px-4 py-6">
			<div className="flex-col w-full sm:mr-10 sm:border-r sm:pr-10">
				<div className="text-center text-2xl font-bold">Create an account</div>
				<div className="mt-2 text-center text-sm text-muted-foreground">
					Already have an account?{" "}
					<Link to="/signin" className="underline text-primary font-medium">
						Sign in
					</Link>
				</div>
				<form
					className="mt-4"
					onSubmit={(e) => {
						e.preventDefault();
						mutate({ emailAddress, password });
					}}
				>
					<div className="grid gap-2 mt-4">
						<Label htmlFor="email">Email</Label>
						<Input
							id="email"
							type="email"
							placeholder="m@example.com"
							required
							autoCapitalize="none"
							autoComplete="email"
							autoCorrect="off"
							disabled={isPending}
							value={emailAddress}
							onChange={(e) => setEmailAddress(e.target.value)}
						/>
					</div>
					<div className="grid gap-2 mt-4">
						<Label htmlFor="password">Password</Label>
						<Input
							id="password"
							type="password"
							required
							autoCapitalize="none"
							autoComplete="current-password"
							autoCorrect="off"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							disabled={isPending}
						/>
					</div>
					<div className="flex items-center space-x-2 mt-6">
						<Checkbox id="terms" checked={terms} onCheckedChange={setTerms} />
						<Label
							htmlFor="terms"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							Accept our{" "}
							<Link to="/terms" className="underline" target="_blank">
								Terms and Conditions
							</Link>{" "}
							&{" "}
							<Link to="/privacy" className="underline" target="_blank">
								{" "}
								Privacy Policy
							</Link>
						</Label>
					</div>
					<Button
						id="signup"
						className="w-full mt-6"
						type="submit"
						disabled={isPending || !emailAddress || !password || !terms}
					>
						{isPending && <Spinner className="mr-2" />}Sign up
					</Button>
				</form>
			</div>
			<div className="hidden sm:block flex-col items-center w-full">
				<div className="space-y-4 h-full">
					<div className="space-y-2">
						<h2 className="text-2xl font-bold">Subscription</h2>
						<p className="">$7.99 per month</p>
					</div>
					<div className="space-y-2">
						<h2 className="text-2xl font-bold">Features</h2>
						<ul className="space-y-2">
							<li>
								<Check className="mr-2 inline-block h-4 w-4 text-green-500" />
								Create unlimited systems and techniques
							</li>
							<li>
								<Check className="mr-2 inline-block h-4 w-4 text-green-500" />
								Sync your systems and techniques across all devices
							</li>
							<li>
								<Check className="mr-2 inline-block h-4 w-4 text-green-500" />
								Clip YouTube videos
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
