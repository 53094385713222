import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Technique } from "@/routes/techniques/components/technique";
import { useNavigate } from "@tanstack/react-router";

export function TechniqueSheet({
	techniqueId,
	isEditing,
}: {
	techniqueId: string | undefined;
	isEditing: boolean;
}) {
	const navigate = useNavigate();
	return (
		<Sheet
			open={!!techniqueId}
			onOpenChange={(open) => {
				if (!open) {
					navigate({ search: {} });
				}
			}}
		>
			<SheetContent className="bg-secondary w-full sm:w-[540px] md:w-[640px] lg:w-[740px] xl:w-[840px]">
				<ScrollArea className="h-full mt-4">
					{techniqueId && <Technique id={techniqueId} isEditing={isEditing} />}
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
}
